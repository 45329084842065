<template>
  <div class="about">
    <!-- <div class="qiehuan">
      <el-button type="warning" size="small" class="qh">切换大屏展示</el-button>
    </div> -->

    <div class="qiehuan">
      <!-- <el-button type="warning" size="small" class="qh">切换大屏展示</el-button> -->
      <el-tooltip
        class="item"
        effect="dark"
        :content="qb"
        placement="top-start"
      >
        <el-button
          type="warning"
          size="mini"
          class="add"
          icon="el-icon-monitor"
          style="margin-left: 10px"
          @click="quan"
        ></el-button>
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        :content="czText"
        placement="top-start"
      >
        <el-button
          type="danger"
          size="mini"
          class="add"
          plain
          @click="czFun"
          icon="el-icon-setting"
        ></el-button>
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        content="添加设备"
        placement="top-start"
      >
        <el-button
          type="success"
          size="mini"
          class="add"
          icon="el-icon-circle-plus-outline"
          @click="add('adddev', '', '添加设备')"
        ></el-button>
      </el-tooltip>
      <el-dropdown :hide-on-click="false"  style="    float: right;
    margin-top: 7px;    color: #0f65e4;
    font-weight: bold;" >
        <span class="el-dropdown-link">
          显示列<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown" >

 
  
          <el-dropdown-item v-for="item in listoptions" :key="item"> <el-checkbox :label="item.name"  v-model=" item.checked" @change="listFunN(item)"></el-checkbox></el-dropdown-item>

        </el-dropdown-menu>
      </el-dropdown>
      <!-- <el-select v-model="listTitle" multiple placeholder="请选择" style="width:150px;    float: right;"      size="small">
          <el-option
            v-for="item in listoptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select> -->

      <!-- <el-select
        v-model="typeV"
        filterable
        placeholder=""
        @change="typeI"
        size="small"
        class="add"
        style="width: 200px"
      >
        <el-option label="请选择物理机" value=""></el-option>
        <el-option
          :label="item.hostname"
          :value="item._id"
          v-for="(item, index) in hostdevAry"
          :key="index"
        >
        </el-option>
      </el-select> -->

      <p class="sou">
        <el-select
          v-model="locationV"
          filterable
          placeholder="请选择设备位置"
          @change="locationFunI"
          size="small"
          style="width: 150px; float: left; margin-right: 5px"
        >
          <el-option label="请选择设备位置" value=""></el-option>
          <el-option
            :label="item.title"
            :value="item._id"
            v-for="(item, index) in locationAry"
            :key="index"
          ></el-option>
        </el-select>

        <el-select
          v-model="companyV"
          filterable
          @change="companyFunI"
          placeholder=""
          size="small"
          style="width: 150px; float: left; margin-right: 5px"
        >
          <el-option label="请选择所属单位" value=""></el-option>
          <el-option
            :label="item.title"
            :value="item._id"
            v-for="(item, index) in companyAry"
            :key="index"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="Stype"
          filterable
          placeholder="设备类型筛选"
          @change="locationFunS"
          size="small"
          style="width: 150px; float: left; margin-right: 30px"
        >
          <el-option label="全部设备类型" value=""></el-option>

          <el-option label="物理机" value="物理机"></el-option>
          <el-option label="虚拟机" value="虚拟机"></el-option>
        </el-select>

        <el-select
          v-model="valueS"
          filterable
          placeholder=""
          @change="changeFun"
          size="small"
          style="width: 150px; float: left; margin-right: 2px"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-input
          v-model="input"
          placeholder="请输入对应检索项内容"
          style="width: 150px; float: left; margin-right: 2px"
          size="small"
          @change="searchFun"
        ></el-input>

        <!-- <el-button type="success" plain @click="moreFun" size="small">{{
          isSlectName
        }}</el-button> -->
        <el-tooltip
          class="item"
          effect="dark"
          :content="isSlectName"
          placement="top-start"
        >
          <el-button
            type="info"
            plain
            size="mini"
            @click="moreFun"
            icon="el-icon-more"
          ></el-button>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="搜索"
          placement="top-start"
        >
          <el-button
            type="primary"
            size="mini"
            @click="searchFun"
            icon="el-icon-search"
          ></el-button>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="重置"
          placement="top-start"
        >
          <el-button
            type="danger"
            size="mini"
            @click="resFun"
            icon="el-icon-refresh-left"
          ></el-button>
        </el-tooltip>
      </p>
    </div>

    <div v-show="isSlect" style="margin-bottom: 5px; overflow: hidden">
      <el-select
        v-model="selectH"
        style="width: 120px; float: left"
        class="hyy"
        size="small"
      >
        <el-option label="标签（且）" value="且"></el-option>
        <el-option label="标签（或）" value="或"></el-option>
      </el-select>
      <el-select
        size="small"
        v-model="Bq"
        placeholder="请选择标签"
        style="width: 250px; float: left; margin-left: -2px"
        multiple
        class="hyy-border"
      >
        <el-option-group
          v-for="group in dynamicTags"
          :key="group._id"
          :label="group.title"
        >
          <el-option
            v-for="item in group.labels"
            :key="item._id"
            :label="item.title"
            :value="item._id"
          >
          </el-option>
        </el-option-group>
      </el-select>
    </div>
    <div style="text-align: left; color: red">
      温馨提示：双击列表每行的数据可进入查看、编辑
    </div>
    <el-table
      ref="table"
      :data="tableData"
      border
      @row-dblclick="rowdbFun"
      @cell-mouse-enter="cellFun"
      style="width: 100%"
      v-loading="loading"
      element-loading-text="安装中"
      element-loading-spinner="el-icon-loading"
    >
      <el-table-column
        type="index"
        :index="indexFun"
        width="50"
        label="序号"
        fixed="left"
      >
      </el-table-column>
      <el-table-column prop="hostname" label="主机名" width="180">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="right">
            <p
              v-if="scope.row.hostname != scope.row.realhostname"
              style="color: rgb(204, 88, 18)"
            >
              【 当前主机名与实际设备主机名不一致，实际设备主机名为：<span
                style="color: #fff"
                >{{ scope.row.realhostname }}</span
              >
              。可在操作中同步设置主机名到设备中 】
              <!-- <el-button
                type="danger"
                size="mini"
                @click="zjName(scope.row._id, scope.row.realhostname)"
                >点击同步主机名</el-button
              > -->
            </p>
            <p>
              设备名称：<span class="fk">{{ scope.row.title }}</span>
            </p>
            <p>
              ip： <span class="fk">{{ scope.row.ip }}</span>
            </p>
            <P
              >upTime：
              <span class="fk">{{
                scope.row.uptime ? scope.row.uptime : "暂无"
              }}</span></P
            >

            <!-- <p></p> -->
            <!-- <p>
              {{ scope.row._sys_label ? "标签： " : "标签：暂无"
              }}<el-tag
                v-for="(tag, index) in scope.row._sys_label"
                :key="index"
                :style="`color:${tag.color}`"
              >
                {{ tag.title }}
              </el-tag>
            </p> -->
            <!-- <div>
              
            </div> -->

            <div slot="reference" class="name-wrapper">
              {{ scope.row.hostname
              }}<i
                v-if="scope.row.hostname != scope.row.realhostname"
                class="el-icon-warning"
                style="color: rgb(204 88 18); margin-left: 5px"
              ></i>
            </div>
          </el-popover>
        </template>
      </el-table-column>

      <!-- <el-table-column prop="ip" label="ip地址"> </el-table-column> -->
      <!-- <el-table-column prop="title" label="设备名称"> </el-table-column> -->
      <el-table-column prop="type" label="类型" v-if="listoptions[0].checked">
        <template slot-scope="scope">
          <span
            v-if="scope.row.type == '物理机'"
            style="color: rgb(6, 134, 255)"
            >{{ scope.row.type }}</span
          >
          <span v-else>{{ scope.row.type }}</span>
        </template>
        <!-- <template slot-scope="scope">
          <div v-if="scope.row.type == '物理机'">
            <el-popover
              trigger="click"
              placement="right"
              @show="showW(scope.row._id)"
            >
              <div v-if="wlJAry.length > 0">
                <p>所关联的虚拟机：</p>

                <p v-for="(item, index) in wlJAry" :key="index">
                  {{ item.title
                  }}<span style="color: #625f5f; margin-left: 15px">{{
                    item.hostname
                  }}</span>
                </p>
              </div>
              <p v-else>所关联的虚拟机：暂无</p>
              <div
                slot="reference"
                class="name-wrapper"
                style="font-weight: bold; color: rgb(6 134 255)"
              >
                {{ scope.row.type }}
              </div>
            </el-popover>
          </div>
          <div v-else>
            <div slot="reference" class="name-wrapper">
              {{ scope.row.type }}
            </div>
          </div>
        </template> -->
      </el-table-column>
      <el-table-column prop="cpu_count" label="CPU核" v-if="listoptions[1].checked"> </el-table-column>
      <el-table-column prop="cpu_usage" label="CPU使用率" width="100" >
      </el-table-column>
      <el-table-column prop="tcp_count" label="tcp连接" v-if="listoptions[2].checked"> </el-table-column>

      <el-table-column prop="_sys_label" label="标签" width="150" v-if="listoptions[3].checked">
        <template slot-scope="scope">
          <el-tag
            v-for="(tag, index) in scope.row._sys_label"
            :key="index"
            :style="`color:${tag.color};margin:0 0 2px 1px;padding:0 2px;height:22px;
          line-height:22px;`"
          >
            {{ tag.title }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column prop="load_status" label="负载">
        <template slot-scope="scope">
          <span v-if="scope.row.load_status > 20" style="color: rgb(255 0 0)">{{
            scope.row.load_status
          }}</span>
          <span
            v-else-if="scope.row.load_status > 5"
            style="color: rgb(255 166 4)"
            >{{ scope.row.load_status }}</span
          >
          <span v-else style="color: rgb(103, 194, 58)">{{
            scope.row.load_status
          }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="speed_in" label="网络流入速率（MB/s）" width="170" v-if="listoptions[4].checked">
      </el-table-column>
      <el-table-column
      v-if="listoptions[5].checked"
        prop="speed_out"
        label="网络流出速率（MB/s）"
        width="170"
      >
    
    
        
      </el-table-column>
      <el-table-column prop="ram_status" label="总内存（GB）" >
      </el-table-column>

      <el-table-column prop="rootpercent" label="磁盘使用率" width="100">
      </el-table-column>
      <el-table-column prop="start_time" label="开始时间" v-if="listoptions[6].checked"> </el-table-column>
      <el-table-column prop="end_time" label="结束时间" v-if="listoptions[7].checked"> </el-table-column>
      <el-table-column
        prop="ram_percent"
        label="内存使用率"
        width="100"
        fixed="right"
      >
      </el-table-column>
      <el-table-column prop="run_status" label="在线状态" fixed="right"  width="100" >
        <template slot-scope="scope">
          <span v-if="scope.row.run_status == '在线'" style="color: #67c23a">{{
            scope.row.run_status
          }}</span>
          <span v-else style="color: #ccc">{{ scope.row.run_status }}</span>
        </template>
      </el-table-column>

      <el-table-column fixed="right" label="操作" width="240" v-if="czL">
        <template slot-scope="scope">
          <!-- <i
            class="el-icon-edit"
         
            style="cursor: pointer; color: #409eff"
          ></i> -->

          <!-- </el-tooltip> -->
          <!-- <el-tooltip content="删除" placement="top"> -->

          <i
            class="el-icon-refresh"
            @click="refresh(scope.row._id)"
            style="cursor: pointer; color: #67c23a"
          ></i>
          <i
            class="el-icon-zoom-in"
            @click="des(scope.row)"
            style="cursor: pointer; color: rgb(163 34 238); margin-left: 15px"
          ></i>
          <!---    -->
          <el-popconfirm
            :title="`确定同步主机名？`"
            @confirm="zjName(scope.row._id, scope.row.realhostname)"
            v-if="scope.row.hostname != scope.row.realhostname"
          >
            <i
              class="el-icon-sort"
              slot="reference"
              style="margin-left: 15px; cursor: pointer; color: #409eff"
            ></i>
          </el-popconfirm>

          <!-- <el-popover trigger="hover" placement="top"> -->
          <!-- <p>同步主机名功能正在开发中...</p> -->

          <!-- <i
           
              class="el-icon-sort"
              slot="reference"
              style="margin-left: 15px; cursor: pointer; color: #409eff"
            ></i> -->

          <!-- </el-popover> -->

          <a-icon
            type="code"
            style="margin-left: 15px; cursor: pointer; color: rgb(1 166 201)"
            v-if="scope.row.ssh != ''"
            @click="goNewFun(scope.row.ssh)"
          />
          <!-- <el-button type="primary"  size="mini" style="margin-left:15px;">SNMP</el-button> -->
          <a-tooltip>
            <template slot="title"> 安装SNMP </template>
            <a-icon
              v-if="scope.row.snmp == ''"
              style="margin-left: 15px; cursor: pointer; color: orange"
              type="tool"
              @click="anmpFun(scope.row._id)"
            />
          </a-tooltip>
          <a-tooltip v-if="scope.row.todesk == '1'">
            <template slot="title"> todesk </template>

            <a-icon
              type="fund"
              style="
                cursor: pointer;
                color: rgb(6, 160, 221);
                margin-left: 15px;
              "
            />
          </a-tooltip>
          <a-tooltip>
            <template slot="title"> 远程开关机 </template>
            <!--- @click="plotFun(scope.row._id)"-->
            <a-icon
              type="box-plot"
              style="
                cursor: pointer;
                color: rgb(156 195 255);
                margin-left: 15px;
              "
            />
          </a-tooltip>
          <a-tooltip v-if="scope.row.webserver">
            <template slot="title"> 访问服务 </template>
            <!--- @click="plotFun(scope.row._id)"-->
            <a-icon
              type="link"
              style="cursor: pointer; color: rgb(223 236 6); margin-left: 15px"
              @click="goNewFun(scope.row.webserver)"
            />
          </a-tooltip>

          <el-popconfirm
            :title="`确定删除${scope.row.title}？`"
            @confirm="del(scope.row._id, scope.row.title)"
          >
            <i
              class="el-icon-delete"
              slot="reference"
              style="cursor: pointer; color: red; margin-left: 15px"
            ></i>
          </el-popconfirm>
          <!-- </el-tooltip> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="currentP"
      @prev-click="preFun"
      @next-click="nextFun"
    >
    </el-pagination> -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="currentP"
      :current-page="currentPage4"
      :page-sizes="[15, 20, 25, 30]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!---编辑/添加-->
    <el-dialog :title="title" :visible.sync="dialogFormVisible">
      <el-form
        :model="ruleFormA"
        :rules="rulesA"
        ref="ruleFormA"
        label-width="100px"
        class="demo-ruleForm"
      >
        <!-- <div class="title-container">
          <h3 class="title">登录</h3>
        </div> -->

        <el-form-item label="设备名称" prop="title">
          <el-input v-model="ruleFormA.title" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="ip地址" prop="ip">
          <el-input v-model="ruleFormA.ip" placeholder=""></el-input>
          <!-- <inputIp @change="changeIp" @getIpVal="getIpVal" :value="ruleFormA.ip"></inputIp> -->
        </el-form-item>

        <el-form-item label="主机名" prop="hostname">
          <el-input v-model="ruleFormA.hostname" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="管理员密码" prop="password" v-if="showpsw">
          <!--- -->
          <el-input v-model="ruleFormA.password" placeholder=""></el-input>
        </el-form-item>

        <!-- <el-form-item label="用途" prop="use">
          <el-input
            v-model="ruleFormA.use"
            placeholder="请输入用途"
            type="textarea"
            class="addStyle"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="类型" prop="type">
          <el-input v-model="ruleFormA.type" placeholder="请输入类型"></el-input>
        </el-form-item> -->

        <!-- <el-form-item label="类型" prop="type">
          <el-select v-model="ruleFormA.type" placeholder="" @change="typeFun">
            <el-option label="虚拟机" value="虚拟机"></el-option>
            <el-option label="物理机" value="物理机"></el-option>
           
          </el-select>
        </el-form-item> -->
        <el-form-item label="宿主机" prop="hostdev">
          <el-select
            filterable
            v-model="ruleFormA.hostdev"
            placeholder=""
            :disabled="hostdevD"
          >
            <el-option
              :label="item.hostname"
              :value="item._id"
              v-for="(item, index) in hostdevAry"
              :key="index"
            >
              <span style="float: left">{{ item.hostname }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.title
              }}</span>
            </el-option>
            <!-- <el-option label="虚拟机" value="虚拟机"></el-option>
            <el-option label="物理机" value="物理机"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="服务学校" prop="school">
          <el-input v-model="ruleFormA.school" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="负责人" prop="manager">
          <el-select
            v-model="ruleFormA.manager"
            placeholder=""
            multiple
            filterable
          >
            <el-option
              :label="item.name"
              :value="item._id"
              v-for="(item, index) in fzAry"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备位置" prop="location">
          <el-select v-model="ruleFormA.location" filterable placeholder="">
            <el-option
              :label="item.title"
              :value="item._id"
              v-for="(item, index) in locationAry"
              :key="index"
            ></el-option>
            <!-- <el-option label="虚拟机" value="虚拟机"></el-option>
            <el-option label="物理机" value="物理机"></el-option> -->
          </el-select>
        </el-form-item>

        <el-form-item label="所属单位" prop="company">
          <el-select v-model="ruleFormA.company" filterable placeholder="">
            <el-option
              :label="item.title"
              :value="item._id"
              v-for="(item, index) in companyAry"
              :key="index"
            ></el-option>
            <!-- <el-option label="虚拟机" value="虚拟机"></el-option>
            <el-option label="物理机" value="物理机"></el-option> -->
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="状态" prop="state">
          <el-select v-model="ruleFormA.state" placeholder="">
            <el-option label="可用" value="可用"></el-option>
            <el-option label="过期" value="过期"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="开始时间" prop="start_time">
          <el-date-picker
            v-model="ruleFormA.start_time"
            type="date"
            placeholder=""
            style="width: 100%"
            format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="end_time">
          <el-date-picker
            v-model="ruleFormA.end_time"
            type="date"
            placeholder=""
            style="width: 100%"
            format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item
          label="网络设备类型"
          prop="nettype"
          :style="showpsw ? 'width:50%;' : ''"
        >
          <el-select
            v-model="ruleFormA.nettype"
            filterable
            placeholder="请选择网络设备类型"
          >
            <el-option label="桌面路由" value="桌面路由"></el-option>
            <el-option label="控控" value="控控"></el-option>
            <!-- <el-option label="虚拟机" value="虚拟机"></el-option>
            <el-option label="物理机" value="物理机"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="todesk" prop="location" style="text-align: left">
          <el-checkbox v-model="ruleFormA.todeskCed"></el-checkbox>
        </el-form-item>
        <el-form-item
          label="备注"
          prop="comment"
          :style="!showpsw ? 'width:100%;' : ''"
        >
          <el-input
            v-model="ruleFormA.comment"
            placeholder=""
            type="textarea"
            class="addStyle"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="管理员密码" prop="password"   style="visibility: hidden"
          v-if=" widthS > 800">
          <el-input v-model="ruleFormA.password" placeholder=""></el-input>
        </el-form-item> -->
        <el-form-item label="设备配置" style="width: 100%">
          <el-table :data="dataSourceBtoom" border style="width: 100%">
            <el-table-column prop="date" label="cpu" width="180">
              <template header> cpu </template>
              <template slot-scope="scope">
                <el-input v-model="scope.row.typeP"></el-input>
              </template>
            </el-table-column>

            <el-table-column prop="name" label="内存" width="100">
              <template #header> 内存 </template>
              <template slot-scope="scope">
                <el-input v-model="scope.row.typeN"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="存储">
              <template #header> 存储 </template>
              <template slot-scope="scope">
                <el-input v-model="scope.row.typeC"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="gpu">
              <template #header> gpu </template>
              <template slot-scope="scope">
                <el-input v-model="scope.row.typeG"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="操作系统版本">
              <template #header> 操作系统版本 </template>
              <template slot-scope="scope">
                <el-input v-model="scope.row.typeB"></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="账户列表" prop="account" style="width: 100%">
          <!-- <el-button size="small" @click="open">展开</el-button> -->
          <!-- <i class="el-icon-circle-plus-outline"></i> -->
          <el-button
            type="success"
            @click="handleAdd"
            size="small"
            style="float: left; margin-bottom: 5px"
            >添加</el-button
          >

          <el-table
            :data="dataSource"
            style="width: 100%"
            max-height="300"
            border
          >
            <el-table-column prop="name" label="账号" :resizable="false">
              <template slot-scope="scope">
                <el-input v-model="scope.row.name" placeholder=""></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="pass" label="密码" :resizable="false">
              <template slot-scope="scope">
                <el-input v-model="scope.row.pass" placeholder=""></el-input>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="emial"
              label="邮箱"
    
              :resizable="false"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.email"
                  placeholder="请输入邮箱"
                ></el-input>
              </template>
            </el-table-column> -->

            <el-table-column
              label="操作"
              width="120"
              fixed="right"
              :resizable="false"
            >
              <template slot-scope="scope">
                <!--   <a-popconfirm
              
                  :title="`确定删除账号？`"
                  cancelText="取消"
                  okText="确定"
                  @confirm="() => onDelete(scope.$index, dataSource)"
                > -->
                <a
                  href="javascript:;"
                  @click="() => onDelete(scope.$index, dataSource)"
                  >删除</a
                >
                <!-- </a-popconfirm> -->
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>

        <el-form-item label="域名列表" prop="host" style="width: 100%">
          <el-button
            type="success"
            @click="handleAddHost"
            size="small"
            style="float: left; margin-bottom: 5px"
            >添加</el-button
          >

          <el-table
            :data="dataSourceHost"
            style="width: 100%"
            max-height="300"
            border
          >
            <el-table-column prop="name" label="域名" :resizable="false">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.name"
                  placeholder=""
                  @input="change($event)"
                ></el-input>
              </template>
            </el-table-column>

            <!-- <el-table-column prop="" label="域名" :resizable="false">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.name"
                  placeholder="请输入域名"
                  @input="change($event)"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="" label="端口" :resizable="false">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.name"
                  placeholder="请输入端口"
                  @input="change($event)"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="" label="状态" :resizable="false">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.name"
                  placeholder="请输入状态"
                  @input="change($event)"
                ></el-input>
              </template>
            </el-table-column> -->

            <el-table-column
              label="操作"
              width="120"
              fixed="right"
              :resizable="false"
            >
              <template slot-scope="scope">
                <a
                  href="javascript:;"
                  @click="() => onDelete(scope.$index, dataSourceHost)"
                  >删除</a
                >
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item
          label="标签"
          style="width: 100%"
          class="tags"
          prop="_sys_label"
        >
          <template>
            <el-button
              type="primary"
              size="small"
              style="float: left; margin-bottom: 5px"
              @click="setTagFun"
              >设置标签</el-button
            >
            <div style="width: 100%; overflow: hidden">
              <div style="float: left; padding: 5px">
                <div v-if="tagsCurrent.length > 0">
                  当前选中：<el-tag
                    v-for="tag in tagsCurrent"
                    :key="tag.title"
                    :style="`color:${tag.color}`"
                  >
                    {{ tag.title }}
                  </el-tag>
                </div>
                <div v-else>
                  当前选中标签：<span style="color: red">暂无</span>
                </div>
              </div>
            </div>
          </template>
        </el-form-item>
        <!-- <el-form-item label="设置标签" prop="account" style="width: 100%">
          <a-popover title="Title"  :key="tag" v-for="tag in dynamicTags">
            <template slot="content" >
              <p @close="handleClose(tag)">删除标签</p>
              <p >编辑标签</p>
            </template>
            <el-tag
        
        :disable-transitions="false"

      >
        {{ tag }}
      </el-tag>
          </a-popover>
         
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="showInput"
            >+ 添加标签</el-button
          >
        </el-form-item> -->
        <el-form-item
          label="敏感信息"
          prop="seninfo"
          v-if="title == '添加设备'"
          style="width: 100%"
          class="min"
        >
          <el-input
            v-model="ruleFormA.seninfo"
            placeholder=""
            type="textarea"
            class="addStyle"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="敏感信息"
          v-if="title == '编辑'"
          style="width: 100%"
          class="min"
          prop="seninfo"
        >
          <template>
            <div>
              <el-button
                style="float: left"
                @click="look"
                size="small"
                type="warning"
                v-show="isLook && !yz"
                >查看</el-button
              >
              <el-dialog
                :append-to-body="true"
                title="提示"
                :visible.sync="lookValue"
                width="30%"
              >
                <span>需注册手机号接收验证码，并输入验证正确。是否查看？</span>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="lookValue = false">取 消</el-button>
                  <el-button type="primary" @click="lookFun">查 看</el-button>
                </span>
              </el-dialog>
              <el-input
                v-show="!isLook && !yz"
                v-model="dx"
                style="width: 160px; float: left"
                size="small"
                placeholder="请输入短信验证码"
                class="addStyle"
              ></el-input>
              <el-button
                style="float: left; margin-top: 3px; margin-left: 10px"
                @click="yanz"
                size="small"
                type="warning"
                v-show="!isLook && !yz"
                >验证</el-button
              >
              <el-input
                v-if="yz"
                v-model="ruleFormA.seninfo"
                type="textarea"
                class="addStyle"
              ></el-input>
            </div>
          </template>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dataSet(title)">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="详情" :visible.sync="dialogDes" width="30%">
      <!-- <el-descriptions
              class="margin-top"
              title=""
              :column="4"
              v-for="(item,index) in disk_status"
              :key='index'
            
            >
              <el-descriptions-item>
                <template slot="label">
                  目录
                </template>
                {{item.path}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  总空间
                </template>
                {{item.allspace}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  已用空间
                </template>
                {{item.usedspace}}
              </el-descriptions-item> <el-descriptions-item>
                <template slot="label">
                  使用百分比
                </template>
                {{item.diskpercent}}
              </el-descriptions-item>

             
            </el-descriptions> -->
      <el-divider content-position="left">磁盘</el-divider>
      <el-table :data="disk_status" border style="width: 100%" height="250">
        <el-table-column prop="path" label="目录" width="180">
        </el-table-column>
        <el-table-column prop="allspace" label="总空间" width="180">
        </el-table-column>
        <el-table-column prop="usedspace" label="已用空间"> </el-table-column>
        <el-table-column prop="diskpercent" label="使用百分比">
        </el-table-column>
      </el-table>
      <el-divider content-position="left">网络</el-divider>
      <el-table :data="inoutspeed" border style="width: 100%" height="250">
        <el-table-column
          type="index"
          :index="indexFunW"
          width="50"
          label="网口"
        >
          <!-- <el-table-column prop="path" label="网口" width="180"> -->
        </el-table-column>
        <el-table-column prop="inspeed" label="输入流量" width="180">
        </el-table-column>
        <el-table-column prop="outspeed" label="输出流量"> </el-table-column>
        <!-- <el-table-column prop="diskpercent" label="使用百分比"> </el-table-column> -->
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogDes = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!---标签设置弹框-->
    <el-dialog
      title="设置标签"
      append-to-body
      :visible.sync="dialogVisibleTag"
      width="30%"
    >
      <div>
        <el-tabs :tab-position="tabPosition" style="height: 400px">
          <el-tab-pane
            :label="item.title"
            v-for="(item, index) in dynamicTags"
            :key="index"
          >
            <el-tag
              @click="tabFun(itemii._id, itemii.title, itemii.color, indexii)"
              v-for="(itemii, indexii) in item.labels"
              :key="indexii"
              :style="`color:${itemii.color};cursor: pointer;margin:5px;`"
              >{{ itemii.title }}</el-tag
            >
          </el-tab-pane>
          <!-- <el-tab-pane label="配置管理">配置管理</el-tab-pane>
          <el-tab-pane label="角色管理">角色管理</el-tab-pane>
          <el-tab-pane label="定时任务补偿">定时任务补偿</el-tab-pane> -->
        </el-tabs>
        <div style="margin-top: 10px">
          <el-popover
            placement="top-start"
            title=""
            width="600"
            trigger="click"
          >
            <div>
              <div>
                <div style="text-align: left; margin-bottom: 15px">
                  <span style="display: inline-block; width: 20%"
                    >择标签类型名称：</span
                  >
                  <el-select
                    v-model="typeVaD"
                    placeholder="请选择标签类型名称"
                    size="mini"
                    style="width: 50%"
                  >
                    <el-option label="设备标签" value="设备标签"> </el-option>
                    <el-option label="服务标签" value="服务标签"> </el-option>
                    <el-option label="所在系统" value="所在系统"> </el-option>
                  </el-select>
                </div>
                <div style="text-align: left; margin-bottom: 15px">
                  <span style="display: inline-block; width: 20%"
                    >标签名称：</span
                  >
                  <el-input
                    style="width: 50%"
                    v-model="conVaD"
                    placeholder="请输入标签名称"
                    size="mini"
                  ></el-input>
                </div>

                <el-button
                  type="success"
                  size="mini"
                  style="float: right"
                  @click="addBqFun"
                  >添加</el-button
                >
              </div>
            </div>
            <el-button
              slot="reference"
              type="success"
              icon="el-icon-circle-plus-outline"
              size="mini"
              >自定义标签</el-button
            >
          </el-popover>
        </div>
      </div>
      <div
        v-if="tagsCurrent.length > 0"
        style="border-top: 1px solid #000; margin-top: 10px; padding-top: 10px"
      >
        当前选中：<el-tag
          v-for="(tag, index) in tagsCurrent"
          :key="index"
          closable
          :style="`color:${tag.color}`"
          @close="closeTagFun(tag.title, index)"
        >
          {{ tag.title }}
        </el-tag>
      </div>
      <div
        v-else
        style="border-top: 1px solid #000; margin-top: 10px; padding-top: 10px"
      >
        当前选中标签：<span style="color: red">暂无</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleTag = false">关闭</el-button>
        <!-- <el-button type="primary" @click="dialogVisibleTag = false"
          >确 定</el-button
        > -->
      </span>
    </el-dialog>
  </div>
</template>
<script>
import inputIp from "../components/inputIp.vue";
export default {
  components: {
    inputIp,
  },
  data() {
    return {
    
      listoptions: [
      {name:"类型",checked:false},
        {name:"CPU核",checked:false},
        // {name:"CPU使用率",checked:false},
        {name:"tcp连接",checked:false},
        {name:"标签",checked:false},
        
        // {name:"负载",checked:false},
        {name:"网络流入速率（MB/s）",checked:false},
        {name:"网络流出速率（MB/s）",checked:false},
        // {name:"磁盘使用率",checked:false},
        {name:"开始时间",checked:false},
        {name:"结束时间",checked:false},
     
      ],
      listTitle: [],
      Stype: "",
      typeVaD: undefined,
      conVaD: "",
      selectH: "且",
      Bq: "",
      isSlectName: "显示更多条件",
      isSlect: false,
      _sys_creator: "",
      maxHeight: document.body.offsetHeight - 190,
      tabPosition: "left",
      dialogVisibleTag: false,
      tagsCurrent: [],
      czText: "显示操作栏",
      czL: false,
      disk_ok: [],
      dialogDes: false,
      currentTitle: "",
      currentHostname: "",
      lookValue: false,
      loading: false,
      limitVal: 15,
      companyV: "63a94fcdf48c257c5f42f14d",
      locationV: "",
      typeV: "",
      dx: "",
      // qb:"全屏视图",
      isLook: true,
      idC: "",
      yz: false,
      hostdevD: true,
      currentPage4: 1,
      valueS: "title",
      options: [
        { name: "设备名称", id: "title" },
        { name: "IP地址", id: "ip" },
        { name: "主机名", id: "hostname" },
        { name: "服务学校", id: "school" },
      ],
      company: [],
      locationAry: [],
      dialogFormVisible: false,
      input: "",
      id: "",
      dataSource: [],
      dataSourceHost: [],
      tableData: [],
      widthS: document.body.clientWidth,
      dataSourceBtoom: [
        { typeP: "", typeN: "", typeC: "", typeG: "", typeB: "" },
      ],
      // addStyle:'',
      ruleFormA: {
        todeskCed: false,
        start_time: "",
        end_time: "",
        // state: "可用",
        title: "",
        ip: "",
        host: "",
        hostname: "",
        password: "",
        use: "",
        type: "",
        school: "",
        manager: "",
        // managerName: [],
        account: "",
        comment: "",
        hostdev: "",
        location: "",
        company: "",
        seninfo: "",
        nettype: "",
      },
      total: null,
      showpsw: null,
      inoutspeed: [],
      rulesA: {
        manager: [
          { required: true, message: "请选择负责人", trigger: "change" },
        ],
        title: [{ required: true, message: "请输入设备名称", trigger: "blur" }],
        ip: [
          { required: true, message: "请输入ip地址", trigger: "blur" },
          {
            validator: this.checkIp,
            trigger: "blur",
          },
        ],
        hostname: [
          { required: true, message: "请输入主机名", trigger: "blur" },
          {
            validator: this.checkHostname,
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入管理员密码", trigger: "blur" },
          {
            min: 6,
            max: 30,
            message: "密码最少输入6位",
            trigger: "blur",
          },
        ],
        // use: [{ required: true, message: "请输入用途", trigger: "blur" }],
      },
      title: "编辑",
      fzAry: [],
      addType: "",
      hostdevAry: [],
      companyAry: [],
      dynamicTags: [],
      inputVisible: false,
      inputValue: "",
      disk_status: [],
      wlJAry: [],
      msgnumber: 0,
      listO:[]
    };
  },
  props: {
    qb: String,
    companyAryD: [],
  },

  watch: {
    companyAryD: {
      handler(val) {
        console.log(val);
        // 所属单位
        this.companyFun();
        this.companyV = "63a94fcdf48c257c5f42f14d";
        this.init(1, this.limitVal);
      },
    },
  },

  mounted() {
    this.companyFun();
    this.getListFun();
    setInterval(() => {
      this.init(this.currentPage4, this.limitVal);
    }, 60000);
    this.init(1, this.limitVal);
    console.log(this.widthS);
    this.labelFun();
    this.hostdevFun();
    this.locationFun();

    this.fzInit();

  },
  methods: {
    getListFun(){
  
      let data = {
        op: "getcolnum",
     
        // name: name,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))

        .then(function (response) {
          console.log('response.data.data',response.data.data);
          if (response.data.value == "ok") {
            // for(let i=0;i<_this.listoptions.length;i++){
              for(let y=0;y<response.data.data.length;y++){
                response.data.data[y].checked = JSON.parse(response.data.data[y].checked)
              }
              if(response.data.data.length>0){
                _this.listoptions = response.data.data;
              }
             
              console.log('_this.listoptions',_this.listoptions)
            // }
            // _this.$message.success(response.data.msg);
            // _this.init();
            // _this.init(_this.currentPage4, _this.limitVal);
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    listFunN(obj){

      // this.listO=[];
      // this.listO.push({name:obj.name,checked:obj.checked})
      let data = {
        op: "setcolnum",
        data:this.listoptions
        // name: name,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))

        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            // _this.$message.success(response.data.msg);
            // _this.init();
            // _this.init(_this.currentPage4, _this.limitVal);
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      // for(let i=0;i<this.listoptions.length;i++){
      //   if(obj.name==this.listoptions[i].name){
      //     this.listoptions[i].checked = obj.checked;
      //   }
      // }
      // console.log( this.listoptions)

    },
    //添加标签
    addBqFun() {
      if (!this.typeVaD) {
        this.$message.info("请选择标签类型名称！");
        return;
      }
      if (!this.conVaD) {
        this.$message.info("请输入标签名称!");
        return;
      }

      let data = {
        op: "addlabel",
        type: this.typeVaD,
        title: this.conVaD,
        // name: name,
      };
      let _this = this;
      this.$ajax
        .post(this.label, _this.Qs.stringify(data))

        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.$message.success(response.data.msg);
            _this.labelFun();
            // _this.init(_this.currentPage4, _this.limitVal);
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //重置
    resFun() {
      (this.input = ""),
        (this.valueS = "title"),
        (this.currentPage4 = 1),
        (this.limitVal = 15),
        (this.locationV = ""),
        (this.companyV = ""),
        (this.typeV = ""),
        (this.selectH = "且"),
        (this.Bq = "");
      this.init(this.currentPage4, this.limitVal);
    },
    // 更多条件
    moreFun() {
      this.isSlect = !this.isSlect;

      this.isSlectName == "显示更多条件"
        ? (this.isSlectName = "隐藏更多条件")
        : (this.isSlectName = "显示更多条件");
    },
    // 同步主机名
    zjName(id, name) {
      let data = {
        op: "sethostname",
        _id: id,
        // name: name,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))

        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.$message("主机名同步中，5分钟左右可尝试刷新查看！");
            // _this.init(_this.currentPage4, _this.limitVal);
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 物理机关联的虚拟机
    showW(id) {
      let data = {
        op: "vmdevbyhost",
        _id: id,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.wlJAry = response.data.data;
            // _this.total = response.data.data.recordcount;
            // _this.tableData = response.data.data.data;
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 删除标签
    closeTagFun(title, index) {
      this.tagsCurrent.splice(index, 1);
      // debugger
      // delete  this.tagsCurrent[index]
      console.log(this.tagsCurrent);
    },
    // 选中标签
    tabFun(id, title, color, index) {
      // let curTag = this.tagsCurrent.indexOf(title);
      if (this.tagsCurrent.length > 0) {
        let temp = true;
        for (let i = 0; i < this.tagsCurrent.length; i++) {
          if (this.tagsCurrent[i].title == title) {
            temp = false;
            break;
          } else {
            temp = true;
          }
        }
        if (temp) {
          this.tagsCurrent.push({ title: title, id: id, color: color });
        } else {
          this.$message("已选中");
        }
      } else {
        this.tagsCurrent.push({ title: title, id: id, color: color });
      }
      console.log(this.tagsCurrent);
    },
    // 设置标签
    setTagFun() {
      this.dialogVisibleTag = true;
    },
    // 获取标签
    labelFun() {
      let data = {
        op: "labellist",
        labeltype: "",
      };
      let _this = this;
      this.$ajax
        .post(this.label, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.dynamicTags = response.data.data;
            console.log("AAAAAAAAAAAAAAAA", _this.dynamicTags);
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    des(value) {
      this.dialogDes = true;
      console.log(value.disk_status);
      console.log(value.inoutspeed);
      this.disk_status = value.disk_status;
      console.log(this.disk_status);
      this.inoutspeed = value.inoutspeed;
    },
    // handleClose(tag) {
    //   this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    // },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    // 索搜素
    searchFun() {
      this.typeV = "";
      this.init(1);
    },

    // handleInputConfirm() {
    //   let inputValue = this.inputValue;
    //   if (inputValue) {
    //     this.dynamicTags.push(inputValue);
    //   }
    //   this.inputVisible = false;
    //   this.inputValue = "";
    // },
    indexFun(index) {
      return this.limitVal * (this.currentPage4 - 1) + index + 1;
    },
    rowdbFun(row) {
      this.add("editdev", row, "编辑");
    },
    cellFun(row) {
      console.log(row);
    },
    indexFunW(index) {
      return index + 1;
    },
    refreshinit(page, limit) {
      let data = {
        op: "search",
        value: this.input,
        type: this.valueS,
        page: page,
        limit: limit,
        location: this.locationV,
        company: this.companyV,
        hostdev: this.typeV,
        devtype: this.Stype,
        searchlabeltype: this.selectH,
        searchlabel: this.Bq == "" ? "" : this.Bq.join(),
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.total = response.data.data.recordcount;
            _this.tableData = response.data.data.data;
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    refresh(id) {
      let data = {
        op: "refreshsnmp",
        _id: id,
        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            // console.log(response.data.data);
            _this.$message({
              message: response.data.msg,
              type: "success",
            });
            _this.refreshinit(_this.currentPage4, _this.limitVal);
          } else if (response.data.value == "sessionerror") {
            _this.$message.error(response.data.msg);
            setTimeout(() => {
              _this.$router.push({ path: "/" });
            }, 2500);
          } else {
            _this.$message({
              message: response.data.msg,
              type: "warning",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    change() {
      console.log(7);
      this.$forceUpdate();
    },
    czFun() {
      if (this.czText == "显示操作栏") {
        this.czText = "关闭操作栏";
        this.czL = true;
        //在执行完跳转页码的数据请求后
        this.$nextTick(() => {
          this.$refs.table.bodyWrapper.scrollTop = 0;
        });
      } else {
        this.czText = "显示操作栏";
        this.czL = false;
      }
    },
    // ip校验格式
    checkIp(rule, value, callback) {
      let structRight = RegExp(
        /^([1-9]?\d|1\d{2}|2[0-4]\d|25[0-5])(\.([1-9]?\d|1\d{2}|2[0-4]\d|25[0-5])){3}$/
      ).test(value);
      if (!structRight) {
        callback(new Error("IP地址格式有误!"));
      } else {
        callback();
      }
    },
    // 主机名校验格式
    checkHostname(rule, value, callback) {
      console.log(3);
      let structZ = RegExp(/[\u4E00-\u9FA5]/).test(value);
      if (structZ) {
        callback(new Error("主机不能输入中文!"));
      } else {
        callback();
      }
    },
    anmpFun(id) {
      this.loading = true;
      let data = {
        op: "installsnmp",
        _id: id,
        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          _this.loading = false;
          if (response.data.value == "ok") {
            // console.log(response.data.data);
            _this.$message({
              message: response.data.msg,
              type: "success",
            });
            _this.init(_this.currentPage4, _this.limitVal);
          } else {
            _this.$message({
              message: response.data.msg,
              type: "warning",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 15.查看敏感信息验证码获取接口

    look() {
      let data = {
        op: "checkseninfo",
        // title: this.input,
        _id: this.id,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          _this.lookValue = false;
          if (response.data.value == "ok") {
            _this.isLook = false;
            _this.ruleFormA.seninfo = response.data.data;
            _this.yz = true;
          } else {
            _this.lookValue = true;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      // if (seninfo === false) {
      //   this.lookValue = true;
      // } else {
      //   this.ruleFormA.seninfo = seninfo;
      //   this.isLook = false;
      //   this.yz = true;
      // }
    },
    lookFun() {
      // 校验通过
      let data = {
        op: "getvfycode",
        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          _this.lookValue = false;
          if (response.data.value == "ok") {
            // console.log(response.data.data);
            _this.$message({
              message: response.data.msg,
              type: "success",
            });
            _this.isLook = false;
          } else if (response.data.value == "fail") {
            _this.isLook = false;
          } else {
            _this.$message({
              message: response.data.msg,
              type: "warning",
            });
            _this.isLook = true;
            // console.log( _this.isLook)
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 提交验证码
    yanz() {
      // console.log(id)
      // 调取接口
      // 校验通过
      if (this.dx.trim() == "") {
        this.$message({
          message: "请输入验证码！",
          type: "warning",
        });
        return;
      }
      let data = {
        op: "getseninfo",
        _id: this.id,
        code: this.dx,

        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            // console.log(response.data.data);
            _this.ruleFormA.seninfo = response.data.data;
            _this.isLook = false;
            _this.yz = true;
          } else {
            _this.$message({
              message: response.data.msg,
              type: "warning",
            });
            // _this.isLook = true;
            _this.yz = false;
            console.log(_this.isLook);
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      console.log(this.yz);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.limitVal = val;
      this.init(1, val);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    companyFunI(e) {
      this.typeV = "";
      console.log(e);
      this.init(1, this.limitVal);

      this.hostdevFun(e);
    },
    locationFunI() {
      this.typeV = "";
      this.init(1, this.limitVal);
    },
    locationFunS() {
      this.init(1, this.limitVal);
    },
    typeI() {
      (this.input = ""),
        (this.valueS = "title"),
        (this.locationV = ""),
        (this.companyV = ""),
        // (this.typeV = ""),
        (this.selectH = "且"),
        (this.Bq = "");
      this.init(1, this.limitVal);
    },

    init(page, limit = this.limitVal) {
      this.currentPage4 = page;
      let data = {
        op: "search",
        value: this.input,
        type: this.valueS,
        page: page,
        limit: limit,
        devtype: this.Stype,
        location: this.locationV,
        company: this.companyV,
        hostdev: this.typeV,
        searchlabeltype: this.selectH,
        searchlabel: this.Bq == "" ? "" : this.Bq.join(),
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.total = response.data.data.recordcount;
            _this.tableData = response.data.data.data;
            _this.msgnumber = response.data.msgnumber;

            _this.$emit("numberFun", _this.msgnumber);
            //在执行完跳转页码的数据请求后

            _this.$refs.table.bodyWrapper.scrollTop = 0;

            if (_this.currentPage4 != 1 && _this.tableData.length == 0) {
              // 解决删除最后一页的只有一条数据的情况，那就从上一页开始查询
              _this.currentPage4--;
              _this.init(_this.currentPage4, _this.limitVal);
            }
            // _this.fzInit(response.data.data.data);
          } else if (response.data.value == "sessionerror") {
            _this.$message.error(response.data.msg);
            setTimeout(() => {
              _this.$router.push({ path: "/" });
            }, 2500);
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    goNewFun(ssh) {
      let routeUrl = this.$router.resolve({
        path: ssh,
      });
      console.log(routeUrl.href);
      console.log(routeUrl);
      window.open(routeUrl.href.split("#/")[1], "_blank");
    },
    changeIp(ip) {
      console.log(ip);
      this.ruleFormA.ip = ip;
    },
    getIpVal(ip) {
      console.log(ip);
      this.ruleFormA.ip = ip;
    },
    fzInit() {
      let data = {
        op: "userlist",
        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.fzAry = response.data.data;

            console.log(_this.tableData);
          } else {
            // _this.tableData = Ary;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleClick(row) {
      console.log(row);
    },
    handleAdd() {
      const { count, dataSource } = this;
      const newData = {
        name: "",
        pass: "",
        //  email: "",
      };
      this.dataSource = [...dataSource, newData];
      this.count = count + 1;
      //  this.dis = false;
    },
    handleAddHost() {
      const { countHost, dataSourceHost } = this;
      const newData = { name: "" };
      this.dataSourceHost = [...dataSourceHost, newData];
      console.log(this.dataSourceHost);
      this.countHost = countHost + 1;
    },
    onDelete(index, rows) {
      rows.splice(index, 1);
      // this.dis = false;
    },
    changeFun(id) {
      console.log(id);
      this.valueS = id;
    },
    add(type, data, title) {
      if (this.$refs.ruleFormA !== undefined)
        this.$refs.ruleFormA.resetFields();

      this.dx = "";
      this.dialogFormVisible = true;
      this.title = title;
      this.addType = type;
      this.isLook = true;
      this.yz = false;
      console.log(data);
      if (data != "") {
        // 编辑
        this.ruleFormA.title = data.title;
        this.currentTitle = data.title;
        this.ruleFormA.ip = data.ip;
        this.ruleFormA.hostname = data.hostname;
        this.currentHostname = data.hostname;
        this.ruleFormA.host = data.host;
        this.ruleFormA.password = data.password;
        this.ruleFormA.use = data.use;
        this.ruleFormA.type = data.type;
        this.ruleFormA.school = data.school;
        this.ruleFormA.nettype = data.nettype;
        if (data.manager) {
          this.ruleFormA.manager = data.manager;
        } else {
          this.ruleFormA.manager = "";
        }
        // this.ruleFormA.manager = data.manager;
        // this.ruleFormA.state = data.state;
        console.log(data.manager);
        this.ruleFormA.account = data.account;
        this.ruleFormA.comment = data.comment;
        if (data.company) {
          this.ruleFormA.company = data.company;
        } else {
          this.ruleFormA.company = "";
        }
        if (data.location) {
          this.ruleFormA.location = data.location;
        } else {
          this.ruleFormA.location = "";
        }
        if (data.seninfo === false) {
          this.ruleFormA.seninfo = "";
        } else {
          this.ruleFormA.seninfo = data.seninfo;
        }
        if (data.devconf && data.devconf.length > 0) {
          this.dataSourceBtoom = [
            {
              typeP: data.devconf[0],
              typeN: data.devconf[1],
              typeC: data.devconf[2],
              typeG: data.devconf[3],
              typeB: data.devconf[4],
            },
          ];
        } else {
          this.dataSourceBtoom = [
            { typeP: "", typeN: "", typeC: "", typeG: "", typeB: "" },
          ];
        }

        console.log(this.ruleFormA.seninfo);
        this.ruleFormA.todeskCed = data.todesk == "1" ? true : false;
        this.id = data._id;
        // 宿主机
        if (data.hostdev && data.hostdev != "") {
          this.hostdevD = false;
          this.ruleFormA.hostdev = data.hostdev;
        } else {
          this.hostdevD = true;
          this.ruleFormA.hostdev = "";
        }
        if (data.account && data.account != "") {
          this.dataSource = data.account;
        } else {
          this.dataSource = [];
        }
        if (data.host && data.host != "") {
          this.dataSourceHost = data.host;
        } else {
          this.dataSourceHost = [];
        }
        if (data._sys_label) {
          this.tagsCurrent = data._sys_label;
        } else {
          this.tagsCurrent = [];
        }

        this._sys_creator = data._sys_creator;
        console.log(this._sys_creator);
        this.showpsw = data.showpsw;
        this.ruleFormA.start_time = data.start_time;
        this.ruleFormA.end_time = data.end_time;
      } else {
        this.ruleFormA.todeskCed = false;
        this.ruleFormA.nettype = "";
        this._sys_creator = "";
        // this.ruleFormA.state = "可用";
        this.ruleFormA.start_time = "";
        this.ruleFormA.end_time = "";
        this.tagsCurrent = [];
        this.dataSource = [];
        this.dataSourceHost = [];
        this.id = "";
        this.ruleFormA.title = "";
        this.ruleFormA.ip = "";
        this.ruleFormA.hostname = "";
        this.ruleFormA.password = "";
        this.ruleFormA.use = "";
        this.ruleFormA.type = undefined;
        this.ruleFormA.school = "";
        this.ruleFormA.manager = "";
        this.ruleFormA.account = [];
        this.ruleFormA.comment = "";
        this.ruleFormA.hostdev = "";
        this.showpsw = true;
        this.ruleFormA.company = undefined;
        this.ruleFormA.location = undefined;
        this.ruleFormA.seninfo = "";
        this.hostdevD = true;
        this.dataSourceBtoom = [
          { typeP: "", typeN: "", typeC: "", typeG: "", typeB: "" },
        ];
      }
    },
    typeFun(val) {
      console.log(val);
      if (val == "虚拟机") {
        this.hostdevD = false;
      } else {
        this.hostdevD = true;
        this.ruleFormA.hostdev = "";
      }
    },
    del(id, title) {
      let data = {
        op: "deldev",
        _id: id,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.$message({
              message: title + response.data.msg,
              type: "success",
            });

            _this.init(_this.currentPage4, _this.limitVal);
          } else if (response.data.value == "sessionerror") {
            _this.$message.error(response.data.msg);
            setTimeout(() => {
              _this.$router.push({ path: "/" });
            }, 2500);
          } else {
            _this.$message.info({
              message: response.data.msg,
              type: "success",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 宿主机列表获取接口
    hostdevFun(companyid = "") {
      let data = {
        op: "hostdevlist",
        companyid: companyid,
        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            console.log(response.data.data);
            _this.hostdevAry = response.data.data;
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    companyFun() {
      let data = {
        op: "companylist",
        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            console.log(response.data.data);
            _this.companyAry = response.data.data;
            console.log(_this.companyAry);
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    locationFun() {
      let data = {
        op: "locationlist",
        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            console.log(response.data.data);
            _this.locationAry = response.data.data;
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    quan() {
      if (this.qb == "全屏视图") {
        // this.qb = '还原视图'
        this.$emit("quan", false);
      } else {
        // this.qb = '全屏视图'
        this.$emit("quan", true);
      }
    },
    timeTrans(time, type) {
      let date = new Date(new Date(time).getTime() + 8 * 3600 * 1000);
      date = date.toJSON();
      if (type === 1) {
        date = date.substring(0, 10);
      } else {
        date = date.substring(0, 19).replace("T", " ");
      }
      return date;
    },

    dataSet() {
      this.$refs.ruleFormA.validate((valid) => {
        if (valid) {
          if (this.ruleFormA.type == "虚拟机") {
            if (this.ruleFormA.hostdev == "") {
              this.$message.error("类型为“虚拟机”时，宿主机为必选");
              return;
            }
          }
          console.log(this.ruleFormA.seninfo);
          let currentName = [];

          if (
            this.addType == "editdev" &&
            this.currentTitle.trim() == this.ruleFormA.title.trim()
          ) {
            // 名称没变就不提交字段
            currentName = [];
          }
          // else if(this.addType == 'editdev' && (this.currentTitle).trim() != (this.ruleFormA.title).trim()){
          //   currentName = this.ruleFormA.title;
          // }
          else {
            currentName = this.ruleFormA.title.trim();
          }
          let currentHostname = [];
          if (
            this.addType == "editdev" &&
            this.currentHostname.trim() == this.ruleFormA.hostname.trim()
          ) {
            // 名称没变就不提交字段
            currentHostname = [];
          }
          // else if(this.addType == 'editdev' && (this.currentTitle).trim() != (this.ruleFormA.title).trim()){
          //   currentName = this.ruleFormA.title;
          // }
          else {
            currentHostname = this.ruleFormA.hostname.trim();
          }
          let _sys_label_cur = "";
          // if(this.tagsCurrent.length>0){
          //   for(let i=0;i<this.tagsCurrent.length;i++){
          //     if(_sys_label_cur!=''){
          //       _sys_label_cur +=','+this.tagsCurrent[i].title
          //     }
          //     else {
          //       _sys_label_cur +=this.tagsCurrent[i].title
          //     }

          //   }
          // }

          let data = {
            op: this.addType,
            _id: this.id,
            title: currentName,
            ip: this.ruleFormA.ip,
            hostname: currentHostname,
            password: this.ruleFormA.password,
            use: this.ruleFormA.use,
            type: this.ruleFormA.type,
            school: this.ruleFormA.school,
            manager: this.ruleFormA.manager,
            account: this.dataSource.length == 0 ? "" : this.dataSource,
            comment: this.ruleFormA.comment,
            hostdev: this.ruleFormA.hostdev,
            company: this.ruleFormA.company,
            location: this.ruleFormA.location,
            seninfo: this.ruleFormA.seninfo,
            host: this.dataSourceHost.length == 0 ? "" : this.dataSourceHost,
            _sys_label: this.tagsCurrent.length == 0 ? "" : this.tagsCurrent,
            // state: this.ruleFormA.state,
            start_time: this.ruleFormA.start_time
              ? this.timeTrans(this.ruleFormA.start_time, 1)
              : "",
            end_time: this.ruleFormA.end_time
              ? this.timeTrans(this.ruleFormA.end_time, 1)
              : "",
            _sys_creator: this._sys_creator,
            nettype: this.ruleFormA.nettype,
            todesk: this.ruleFormA.todeskCed ? 1 : 0,
            devconf: [
              this.dataSourceBtoom[0].typeP,
              this.dataSourceBtoom[0].typeN,
              this.dataSourceBtoom[0].typeC,
              this.dataSourceBtoom[0].typeG,
              this.dataSourceBtoom[0].typeB,
            ], //配置信息
          };
          let _this = this;
          this.$ajax
            .post(this.device, _this.Qs.stringify(data))
            .then(function (response) {
              console.log(response);
              if (response.data.value == "ok") {
                _this.$message({
                  message: response.data.msg,
                  type: "success",
                });
                _this.dialogFormVisible = false;
                if (_this.addType == "adddev") {
                  _this.currentPage4 = 1;
                  _this.init(1, _this.limitVal);
                } else {
                  _this.init(_this.currentPage4, _this.limitVal);
                }

                _this.hostdevFun();
              } else if (response.data.value == "sessionerror") {
                _this.$message.error(response.data.msg);
                setTimeout(() => {
                  _this.$router.push({ path: "/" });
                }, 2500);
              } else {
                _this.$message.error(response.data.msg);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
        }
      });
    },
    currentP(size) {
      console.log(size);
      this.currentPage4 = size;
      this.init(size, this.limitVal);
    },
    preFun(size) {
      this.init(size, this.limitVal);
    },
    nextFun(size) {
      this.init(size, this.limitVal);
    },
  },
};
</script>
<style lang="scss" scoped>
p .fk {
  margin-bottom: 5px;
  color: #fff;
}
.el-input__inner {
  border: none;
}
.about {
  padding: 10px;
}
.qiehuan {
  min-height: 40px;
  overflow: hidden;
  .qh {
    float: right;
    margin-left: 10px;
  }
  .add {
    float: right;
    margin-bottom: 10px;
  }
  .sou {
    float: left;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    // flex-wrap: wrap;
  }
}
</style>
<style>
/* .hyy .el-input--suffix .el-input__inner {
  background: #535353;
} */
/* .hyy .el-icon-arrow-up:before ,.hyy-border .el-icon-arrow-up:before{

    top: -4px;
}
.hyy .el-icon-arrow-up:after ,.hyy-border .el-icon-arrow-up:after{
  position: absolute;
    height: 100%;
    right: 5px;
    top: 0px;
} */
.hyy-border .el-input--suffix .el-input__inner {
  border-radius: 0 4px 2px 0;
  /* height: 32px !important;
  line-height: 32px; */
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px !important;
  vertical-align: bottom;
}
.about .el-form-item__label {
  /* color: #d4d4d4 !important; */
}
.el-dialog {
  margin-top: 3vh !important;
  width: 60% !important;
}
.el-select {
  width: 100%;
}
.el-form-item {
  width: 33%;
  display: inline-block;
}

.el-textarea__inner {
  resize: none !important;
}
.tabs .el-tag {
  float: left;
}
.min .el-textarea__inner {
  min-height: 150px !important;
}
.el-descriptions {
  border-bottom: 1px solid #ccc;
  padding: 17px 0;
}
/* .el-tag{
  float:left;
} */
thead tr th {
  height: 40px;

  /* background-color: #e3e3e3 !important; */
  /* color: #5b5b5b; */
  font-weight: bold;
}
.el-pagination {
  float: right;
  margin-top: 10px;
}
.el-table .el-table__cell {
  padding: 2px 0 !important;
}
.el-table .el-table__cell {
  margin: 0 !important;
}
@media (max-width: 800px) {
  .el-dialog {
    width: 98% !important;
  }
  .el-form-item {
    width: 100%;
  }
}
</style>
