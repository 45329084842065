<template>
  <a-form-model
    ref="ruleForm"
    :model="form"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <a-form-model-item
      label="密码（请输入6~20位密码）"
      prop="pass"
      ref="pass"
      :labelCol="{ span: 24, offset: 0 }"
      labelAlign="left"
    >
      <a-input-password placeholder="请输入您的密码" v-model="form.pass">
        <a-icon slot="prefix" type="lock" />
      </a-input-password>
    </a-form-model-item>
    <a-form-model-item
      label="确认密码"
      prop="passR"
      ref="passR"
      :labelCol="{ span: 24, offset: 0 }"
      labelAlign="left"
    >
      <a-input-password placeholder="请再次输入您的密码" v-model="form.passR">
        <a-icon slot="prefix" type="lock" />
      </a-input-password>
    </a-form-model-item>

    <a-form-model-item>
      <a-button type="primary" @click="onSubmitReg" style="width: 100%">
        注册
      </a-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
export default {
  data() {
    return {
      visiblereg: false,
      codeBtn: true,
      codeText: "发送验证码",
      countdown: 60,
      labelCol: { span: 4 },
      wrapperCol: { span: 24 },
      form: {
        // name: "",
        // pass: "",
        // code: "",
        // phone: "",
        pass: "",
        passR: "",
      },
      rules: {
        // school: [
        //   {
        //     validator: this.checkSchool,
        //     trigger: "blur",
        //   },
        // ],

        passR: [
          {
            required: true,
            message: "请输入您确认密码",
            trigger: "blur",
          },

          {
            validator: this.checkPassR,
            trigger: "blur",
          },
        ],
        pass: [
          {
            required: true,
            message: "请输入您的密码",
            trigger: "blur",
          },
          {
            min: 6,
            max: 20,
            message: "请输入6-20个字符",
            trigger: "blur",
          },
          {
            validator: this.checkPass,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    onSubmitReg() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 分享链接的登陆舰
          // let joincode = null;
          // if ( window.localStorage.getItem("anjing-joincode")) {
          //   joincode =  window.localStorage.getItem("anjing-joincode");
          // } else {
          //   joincode = "";
          // }
          // let birthday = null;
          // if ( window.localStorage.getItem("anjing-birthdayNew")) {
          //   birthday =  window.localStorage.getItem("anjing-birthdayNew");
          // } else {
          //   birthday = "";
          // }
          let data = {
            op: "reg",
    
            username:  window.localStorage.getItem("anjing-useranjing-nameNew"),
            password: this.form.passR,
            phone:  window.localStorage.getItem("anjing-phoneNew"),
            email:  window.localStorage.getItem("anjing-emailNew"),
            name:  window.localStorage.getItem("anjing-nameNew"),
            addr:window.localStorage.getItem("anjing-addr"),
         
          };
          let _this = this;
          this.$ajax
            .post(this.url + "/new_registration.do", _this.Qs.stringify(data))
            .then(function (response) {
              console.log(response);
              if (response.data.value == "ok") {
                _this.$notification.open({
                  message: "注册",
                  duration: 2,
                  description: response.data.msg,
                  onClick: () => {
                    console.log("注册");
                  },
                });

                 window.localStorage.setItem(
                  "anjing-tokenName",
                  response.data.data.username
                );
                window.localStorage.setItem("anjing-nameNew", response.data.data.name);
                 window.localStorage.setItem("anjing-regPass", _this.form.passR);
                //  window.localStorage.setItem(
                //   "anjing-regPhone",
                //    window.localStorage.getItem("anjing-phoneNew")
                // );

                // 分享链接
                // _this.$emit("submitFun", 2, 1, 0);
                _this.$router.push({path:'/'})
              } else {
                _this.$notification.open({
                  message: "注册",
                  duration: 2,
                  description: response.data.msg,
                  onClick: () => {
                    console.log("注册");
                  },
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          this.current = 0;
          return false;
        }
      });
    },
    // 修改密码
    // checkPass(rule, value, callback) {
    //   if (this.form.passR != "" && this.form.pass != this.form.passR) {
    //     callback(new Error("两次密码输入不一致"));
    //   }
    //   else {
    //       callback();
    //   }
    // },
    checkPassR(rule, value, callback) {
      if (this.form.pass != "" && this.form.pass != this.form.passR) {
        callback(new Error("两次密码输入不一致"));
      } else {
        callback();
      }
    },
  },
};
</script>
<style scoped>
/deep/.add .ant-col-17 {
  width: 100%;
}
.codeQ {
  color: #69a4fa;
  cursor: pointer;
  float: right;
}
/deep/ .ant-form-item-label > label{
    color:#fff;
}
</style>