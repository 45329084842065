<template>
  <div>
    <div
      style="border: 1px solid #ccc; margin-top: 10px"
      v-if="dialogFormVisible"
    >
      <!-- 工具栏 -->
      <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editor"
        :defaultConfig="toolbarConfig"
      />
      <!-- 编辑器 -->
      <Editor
        style="height: 400px; overflow-y: hidden"
        :defaultConfig="editorConfig"
        v-model="html"
        @onChange="onChange"
        @onCreated="onCreated"
      />
    </div>
  </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
export default {
  name: "editorVue",
  components: {
    Editor,
    Toolbar,
  },
  props: {
    content: {
      type: String,
      default: "",
    },
    readOnlys: {
      // 只读
      type: Boolean,
      default: false,
    },
    dialogFormVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogFormV: false,

      editor: null,
      html: "",
      toolbarConfig: {
        /* 显示哪些菜单，如何排序、分组 */
        toolbarKeys: [
          "headerSelect",
          // '|',
          "bold",
          "underline",
          "italic",
          "color",
          "bgColor",
          // '|',
          "indent", // 增加缩进
          "delIndent", // 减少缩进
          "justifyLeft", // 左对齐
          "justifyRight", // 右对齐
          "justifyCenter", // 居中
          "justifyJustify", // 两端对齐
          // '|',
          "fontSize",
          "fontFamily",
          "lineHeight",
          // '|',
          "bulletedList",
          "numberedList",
          "todo",
          "insertLink",
          "insertTable",
          // '|',

          "codeBlock",
          "divider",
          "uploadImage",
          "undo",
          "redo",
        ],
        // excludeKeys: [ ], /* 隐藏哪些菜单 */
      },
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          // 上传图片
          uploadImage: {
            
             customUpload: async(file, insertFn)=> {
              let FormDatas = new FormData();
              FormDatas.append("editormd-image-file", file);
              // FormDatas.get("editormd-image-file");

              // FormDatas.set("editormd-image-file",file);
              // console.log('FormDatas',FormDatas)
              this.$ajax({
                method: "post",
                url: this.url+'/imageUpload.do',
                timeout: 1000,
                headers: {
                  'token': '',//接口需要的token
                  'Content-Type': 'multiart/form-data'
                },

                data: FormDatas,
                TransformReques:[data=> data]
              }).then((res) => {
                insertFn(this.url + res.data.url, "", "");
               
              });
            
            },
            // customInsert: (res, insertFn) => {
            //   connole.log(res, insertFn)
            //   if (res.data.status != 1) {
            //     this.$message.error("上传失败!");
            //     return;
            //   }
            
            //   this.$message.success("上传成功!");
            // },
          },
        },
      },
    };
  },

  methods: {
  
    uploaadImg(file, insertFn) {
      this.$emit("uploadImg", file, insertFn);
    },
    onCreated(editor) {
      this.editor = Object.seal(editor);
      console.log(this.editor);

      //  this.editor.customConfig.pasteTextHandle = true
    },
    onChange(editor) {
      console.log(this.editor.getHtml());
      this.$emit("changeData", this.editor.getHtml());
    },
  },
  created() {
    this.html = this.content;
  },
  watch: {
    readOnlys: {
      handler(newV) {
        if (newV) {
          this.editor.disable(); // 只读模式
        }
      },
    },

    dialogFormVisible: function (val) {
      console.log("显示隐藏", val);
      this.dialogFormV = val;
    },
    content: function (value) {
      console.log("返回的内容", value);
      this.html = value;
    },
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁 editor
  },
};
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style>
/* .w-e-bar-item button[data-menu-key="uploadImage"]{
  display: none;

} */
</style>
