<template>
  <div class="box">
    <div class="box-from">
      <div class="box-she">服务中心</div>
      <div class="zc">
        【登录】
        <!-- 还没有账号？<span @click="goReg" class="go">去注册</span> -->
      </div>
      <!-- <a-tabs default-active-key="1" @change="callback" align-center> -->
      <!-- <a-tab-pane key="1" tab="密码登录">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" class="pass">
        <a-form-model-item
          ref="name"
          label="用户名"
          prop="name"
          :labelCol="{ span: 4, offset: 0 }"
          labelAlign="left"
        >
          <a-input placeholder="请输入您的账号" v-model="form.name">
            <a-icon slot="prefix" type="user"
          /></a-input>
        </a-form-model-item>
  

        <a-form-model-item
          label="密码"
          prop="pass"
          ref="pass"
          :labelCol="{ span: 4, offset: 0 }"
          labelAlign="left"
        >
          <a-input-password placeholder="请输入您的密码" v-model="form.pass">
            <a-icon slot="prefix" type="lock" />
          </a-input-password>
        </a-form-model-item>
  

        <a-form-model-item>
          <a-button type="primary" @click="onSubmit" style="width: 100%">
            登录
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-tab-pane> -->
      <!-- <a-tab-pane key="2" tab="验证码登录" force-render> -->
      <a-form-model
        ref="ruleFormReg"
        :model="form"
        :rules="rules"
        style="margin-top: 10px"
      >
        <a-form-model-item
          ref="phone"
          label="手机号（国际/港澳台号码格式为：国家区号+手机号码）"
          prop="phone"
          :labelCol="{ span: 24, offset: 0 }"
          labelAlign="left"
        >
          <a-input placeholder="请输入您手机号" v-model="form.phone" id="phone">
            <a-icon slot="prefix" type="tablet"
          /></a-input>
        </a-form-model-item>
        <!-- <a-form-model-item label="用户名">
             
            <a-input v-model="form.name" placeholder="请输入您的账号">
              <a-icon slot="prefix" type="user" />
            </a-input>
          </a-form-model-item> -->

        <a-form-model-item
          ref="code"
          prop="code"
          label="验证码"
          :labelCol="{ span: 24, offset: 0 }"
          labelAlign="left"
          style="width: 77%; display: table-cell"
        >
          <a-input placeholder="请输入您的验证码" v-model="form.code">
          </a-input>
        </a-form-model-item>
        <a-form-model-item
          style="width: 25%; display: table-cell; padding-top: 29px"
        >
          <a-button
            type="primary"
            :disabled="codeBtn"
            @click="codeFun"
            style="width: 96px; padding: 0; margin-left: 5px"
          >
            {{ codeText }}
          </a-button>

          <!-- <a-drawer
            title="温馨提示"
            placement="top"
            :closable="false"
            :visible="visible"
            :after-visible-change="afterVisibleChange"
            @close="onClose"
            width="400"
          >
            <lrp />
          </a-drawer> -->
        </a-form-model-item>
        <!-- <p style="overflow: hidden">
          <span class="codeQ" @click="showDrawer">没收到验证码？</span>
        </p> -->
        <a-form-model-item>
          <a-button type="primary" @click="onSubmitReg" style="width: 100%">
            登录
          </a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="danger" @click="qyLogin" style="width: 100%" plain>
            企业微信登录
          </a-button>
        </a-form-model-item>
      </a-form-model>
      <!-- </a-tab-pane>
  </a-tabs> -->
      <!-- <a-form-model ref="ruleForm" :model="form" :rules="rules" class="pass">
        <a-form-model-item
          ref="name"
          label="用户名"
          prop="name"
          :labelCol="{ span: 4, offset: 0 }"
          labelAlign="left"
        >
          <a-input placeholder="请输入您的账号" v-model="form.name">
            <a-icon slot="prefix" type="user"
          /></a-input>
        </a-form-model-item>


        <a-form-model-item
          label="密码"
          prop="pass"
          ref="pass"
          :labelCol="{ span: 4, offset: 0 }"
          labelAlign="left"
        >
          <a-input-password placeholder="请输入您的密码" v-model="form.pass">
            <a-icon slot="prefix" type="lock" />
          </a-input-password>
        </a-form-model-item>


        <a-form-model-item>
          <a-button
            type="primary"
            @click="onSubmit('ruleForm')"
            style="width: 100%"
          >
            登录
          </a-button>
        </a-form-model-item>
      </a-form-model> -->
    </div>
  </div>
</template>
<script>
debugger;
export default {
  data() {
    return {
      codeBtn: true,
      visible: false,
      btnSize: { span: 23, offset: 2 },
      labelCol: { span: 4 },
      wrapperCol: { span: 19 },
      other: "",
      codeText: "发送验证码",
      countdown: 60,
      form: {
        name: "",
        pass: "",
        code: "",
        phone: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入您的账号",
            trigger: "blur",
          },
          {
            min: 1,
            max: 20,
            message: "请输入3-20个字符",
            trigger: "blur",
          },
        ],
        pass: [
          {
            required: true,
            message: "请输入您的密码",
            trigger: "blur",
          },
          {
            min: 3,
            max: 20,
            message: "请输入3-20个字符",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入您的验证码",
            trigger: "blur",
          },
          {
            min: 1,
            max: 6,
            message: "请输入1-6个字符",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入您的手机号",
            trigger: "blur",
          },
          {
            validator: this.checkGrade,
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    if (
      window.localStorage.getItem("anjing-tokenName") &&
      window.localStorage.getItem("anjing-tokenName") != "undefined" &&
      window.localStorage.getItem("anjing-tokenName") != ""
    ) {
      this.form.name = window.localStorage.getItem("anjing-tokenName");
      this.form.pass = window.localStorage.getItem("anjing-regPass");
    }
    if (window.localStorage.getItem("anjing-remPhone") && window.localStorage.getItem("anjing-remPhone")!='') {
      this.form.phone = window.localStorage.getItem("anjing-remPhone") 

      // this.loadPhone();
      document.getElementById('phone').focus()
    }
  },
  methods: {
    callback() {},
    settime() {
      //发送验证码倒计时
      if (this.countdown == 0) {
        this.countdown = 60;
        this.codeText = "发送验证码";

        this.codeBtn = this.phoneCode;
        return;
      } else {
        this.codeText = this.countdown;
        this.codeBtn = true;

        this.countdown--;
      }
      setTimeout(() => {
        this.settime();
      }, 1000);
    },
    qyLogin(){
      let data = {
          op: "login_qywxcode",
          // phone: this.form.phone,
        };
        let _this = this;
        this.$ajax
          .post(this.url + "/new_login.do", _this.Qs.stringify(data))
          .then(function (response) {
            console.log(response);
            if (response.data.value == "ok") {
              // _this.codeBtn = false;
              // _this.phoneCode = false;
              // window.open(response.data.data)
              // _this.$router.push({ path: response.data.data});
              window.location.href=response.data.data
            } else {
              // _this.codeBtn = true;
              // _this.phoneCode = true;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },

    loadPhone(){
      let structRight = RegExp(
        /^(1[3-9]\d{9})$|^(852\d{8})$|^(853\d{8})$|^(886\d{8})$/
      ).test(this.form.phone);
      if (!structRight) {
        this.codeBtn = true;
        this.phoneCode = true;
        this.$message.info('请填写正确的手机号')
      } else {
        let data = {
          op: "check",
          phone: this.form.phone,
        };
        let _this = this;
        this.$ajax
          .post(this.url + "/new_login.do", _this.Qs.stringify(data))
          .then(function (response) {
            console.log(response);
            if (response.data.value == "ok") {
              _this.codeBtn = false;
              _this.phoneCode = false;
            } else {
              _this.codeBtn = true;
              _this.phoneCode = true;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
        }
    },
    checkGrade(rule, value, callback) {
      let structRight = RegExp(
        /^(1[3-9]\d{9})$|^(852\d{8})$|^(853\d{8})$|^(886\d{8})$/
      ).test(value);
      if (!structRight) {
        callback(new Error("请填写正确的手机号"));
        this.codeBtn = true;
        this.phoneCode = true;
      } else {
        let data = {
          op: "check",
          phone: this.form.phone,
        };
        let _this = this;
        this.$ajax
          .post(this.url + "/new_login.do", _this.Qs.stringify(data))
          .then(function (response) {
            console.log(response);
            if (response.data.value == "ok") {
              callback();
              _this.codeBtn = false;
              _this.phoneCode = false;
            } else {
              callback(new Error(response.data.msg));
              _this.codeBtn = true;
              _this.phoneCode = true;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    passTO() {
      this.$router.push({ path: "./pass" });
    },

    codeFun() {
      let data = {
        op: "getcode",
        phone: this.form.phone,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/new_login.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.settime();
          } else {
            _this.$notification.open({
              message: "验证码",
              duration: 4,
              description: response.data.msg,
              onClick: () => {
                console.log("验证码接口");
              },
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 分享链接的登陆舰
          let joincode = null;
          if (window.localStorage.getItem("anjing-joincode")) {
            joincode = window.localStorage.getItem("anjing-joincode");
          } else {
            joincode = "";
          }

          // alert("submit!");
          let data = {
            op: "login_psw",
            username: this.form.name,
            password: this.form.pass,
          };
          let _this = this;
          this.$ajax
            .post(this.url + "/new_login.do", _this.Qs.stringify(data))
            .then(function (response) {
              console.log(response);
              if (response.data.value == "ok") {
                _this.$notification.open({
                  message: "登录",
                  duration: 2,
                  description: "登录成功",
                  onClick: () => {
                    console.log("登录");
                  },
                });
                // _this.$emit("loginFun", false, "ok");
                //  window.localStorage.setItem(
                //   "anjing-tokenName",
                //   response.data.data.name
                // );
                // _this.$router.push({path:_this.$route.query.url})
                window.localStorage.setItem(
                  "anjing-tokenName",
                  response.data.data.username
                );
                window.localStorage.setItem(
                  "anjing-nameNew",
                  response.data.data.name
                );
                window.localStorage.setItem("anjing-regPass", _this.form.pass);
                _this.$router.push({ path: "./tabList" });
                console.log(_this.$route.query);
              } else {
                _this.$notification.open({
                  message: "登录",
                  duration: 2,
                  description: response.data.msg,
                  onClick: () => {
                    console.log("登录");
                  },
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onSubmitReg() {
      // 如果pass==false说明是邀请
      this.$refs.ruleFormReg.validate((valid) => {
        if (valid) {
          // 分享链接的登陆舰
          let joincode = null;
          if (window.localStorage.getItem("anjing-joincode")) {
            joincode = window.localStorage.getItem("anjing-joincode");
          } else {
            joincode = "";
          }
          let data = {
            op: "login_code",
            phone: this.form.phone,
            code: this.form.code,
          };
          let _this = this;
          this.$ajax
            .post(this.url + "/new_login.do", _this.Qs.stringify(data))
            .then(function (response) {
              console.log(response);
              if (response.data.value == "ok") {
                _this.$notification.open({
                  message: "登录",
                  duration: 2,
                  description: response.data.msg,
                  onClick: () => {
                    console.log("登录");
                  },
                });
                window.localStorage.setItem(
                  "anjing-remPhone",
                  _this.form.phone
                );
                // if (
                //    window.localStorage.getItem("anjing-joincode") &&
                //    window.localStorage.getItem("anjing-joincode") != ""
                // ) {
                //   _this.$router.push({ path: "/" });
                // } else {
                //   _this.$emit("loginFun", false, "ok");
                // }
                window.localStorage.setItem(
                  "anjing-tokenName",
                  response.data.data.username
                );
                window.localStorage.setItem(
                  "anjing-applist",
                  JSON.stringify(response.data.applist)
                );
                window.localStorage.setItem(
                  "anjing-nameNew",
                  response.data.data.name
                );
                window.localStorage.setItem("anjing-regPass", _this.form.pass);
                _this.$router.push({ path: "./tabList" });
              } else {
                _this.$notification.open({
                  message: "登录",
                  duration: 2,
                  description: response.data.msg,
                  onClick: () => {
                    console.log("登录");
                  },
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // resetForm(formName) {
    //   this.$refs[formName].resetFields();
    // },
    goReg() {
      this.$router.push({ path: "./reg" });
    },
  },
};
</script>
<style lang="scss" scoped>
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #fff;
$cursor: #fff;
.box {
  background: $bg;
  height: 100vh;
  padding: 10px;
  .box-she {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 30px;
  }
  .box-from {
    width: 400px;
    margin: 150px auto 50px;
    // box-shadow: 0px 0px 19px 3px rgb(104 166 210);
    border-radius: 10px;
    padding: 10px;
    // height:240px
  }
}
</style>
<style scoped>
.codeQ {
  color: #69a4fa;
  cursor: pointer;
  float: right;
}

.el-form-item__label {
  /* color: #fff !important; */
}
.go {
  cursor: pointer;
}
.title {
  font-size: 20px;
  color: #fff;
  text-align: center;
}
.zc {
  color: #7cc5fe;
  text-align: right;
}
/* /deep/ .ant-form-item-label > label{
      color:#fff;
    } */
/deep/ .ant-form-item-label > label {
  color: #fff !important;
}
@media (max-width: 800px) {
  .box-from {
    width: 100% !important;
    box-sizing: border-box;
    margin: 50px auto !important;
  }
}
/deep/ .ant-tabs {
  color: #fff;
}
</style>