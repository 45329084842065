import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'
import reg from '../views/reg.vue'
import pass from '../views/pass.vue'
import tabList from '../views/tabList.vue'

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/reg',
    name: 'reg',
    component: reg
  },
  {
    path: '/pass',
    name: 'pass',
    component: pass
  },
  {
    path: '/bigScreen',
    name: 'bigScreen',
    component: () => import(/* webpackChunkName: "tabList" */ '../views/bigScreen.vue'),

    
  },
  {
    path: '/zl',
    name: 'zl',
    component: () => import(/* webpackChunkName: "about" */ '../views/zl.vue'),

  },
  {
    path: '/school',
    name: 'school',
    component: () => import(/* webpackChunkName: "school" */ '../views/school.vue'),

  },


  {
    path: '/tabList',
    name: 'tabList',
    component: () => import(/* webpackChunkName: "tabList" */ '../views/tabList.vue'),
    // redirect: '/about',
    // component: () => import(/* webpackChunkName: "about" */ '../views/start.vue'),
    children: [
      {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../components/about.vue'),

      },
      {
        path: '/user',
        name: 'user',
        component: () => import(/* webpackChunkName: "about" */ '../components/user.vue'),

      },
     
    ]
  }

 
]

const router = new VueRouter({
  // mode: 'hash',
  // base: process.env.BASE_URL,
  routes
})

export default router
