<template>
  <div class="about jx">
    <!-- <div class="qiehuan">
          <el-button type="warning" size="small" class="qh">切换大屏展示</el-button>
        </div> -->

    <div class="qiehuan">
      <!-- <el-button type="warning" size="small" class="qh">切换大屏展示</el-button> -->

      <el-tooltip
        class="item"
        effect="dark"
        :content="qb"
        placement="top-start"
      >
        <el-button
          type="warning"
          size="mini"
          class="add"
          icon="el-icon-monitor"
          style="margin-left: 10px"
          @click="quan"
        ></el-button>
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        :content="czText"
        placement="top-start"
      >
        <el-button
          type="danger"
          size="mini"
          class="add"
          plain
          @click="czFun"
          icon="el-icon-setting"
        ></el-button>
      </el-tooltip>
      <!-- <el-tooltip
          class="item"
          effect="dark"
          content="发布任务"
          placement="top-start"
        >
          <el-button
            type="success"
            size="mini"
            class="add"
            icon="el-icon-circle-plus-outline"
            @click="add('add', '', '发布任务')"
          ></el-button>
        </el-tooltip> -->

      <p class="sou">
        <!-- <el-checkbox
            v-model="mycreate"
            style="float: left; margin-right: 10px; padding-top: 6px"
            @change="init(1, limitVal)"
            >我发布的</el-checkbox
          >
  
          <el-select
            v-model="stateTop"
            placeholder="请选择状态"
            @change="locationFunI"
            size="small"
            style="width: 150px; float: left; margin-right: 5px"
            class="addFont-color"
          >
            <el-option label="请选择状态" value=""></el-option>
            <el-option label="计划" value="计划"></el-option>
            <el-option label="执行中" value="执行中"></el-option>
            <el-option label="完成" value="完成"></el-option>
            <el-option label="延迟" value="延迟"></el-option>
            <el-option label="取消" value="取消"></el-option>
          </el-select>
          <el-select
            v-model="stateDj"
            placeholder="请选择等级"
            @change="locationFunD"
            size="small"
            style="width: 150px; float: left; margin-right: 5px"
            class="addFont-color"
          >
            <el-option label="请选择等级" value=""></el-option>
            <el-option label="低" value="低"></el-option>
                  <el-option label="中" value="中"></el-option>
                  <el-option label="高" value="高"></el-option>
          </el-select> -->
        <el-input
          v-model="input"
          placeholder="请输入学校"
          style="float: left; margin-right: 2px; width: 150px"
          size="small"
          @change="init(1)"
        ></el-input>

        <el-tooltip
          class="item"
          effect="dark"
          content="搜索"
          placement="top-start"
        >
          <el-button
            type="primary"
            size="mini"
            @click="init(1)"
            icon="el-icon-search"
          ></el-button>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="重置"
          placement="top-start"
        >
          <el-button
            type="danger"
            size="mini"
            @click="resFun"
            icon="el-icon-refresh-left"
          ></el-button>
        </el-tooltip>
      </p>
    </div>
    <div style="text-align: left; color: red">
      温馨提示：双击列表每行的数据可进入查看、编辑
    </div>

    <el-table
      :data="tableData"
      border
      style="width: 100%"
      ref="table"
      v-loading="loading"
      @row-dblclick="rowdbFun"
    >
      <el-table-column
        type="index"
        :index="indexFun"
        width="50"
        label="序号"
        fixed="left"
      >
      </el-table-column>
      <el-table-column prop="schooName" label="学校"> </el-table-column>
      <el-table-column prop="collegeName" label="学院"></el-table-column>
  

      <el-table-column fixed="right" label="操作" width="80" v-if="czL">
        <template slot-scope="scope">
          <i
            class="el-icon-edit"
            @click="add(scope.row)"
            style="cursor: pointer; color: #409eff"
          ></i>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="currentP"
      :current-page="currentPage4"
      :page-sizes="[15, 20, 25, 30]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <!---编辑/添加-->
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :show-close="false"
      width="300px"
    >
      <el-form
        :model="ruleFormA"
        :rules="rulesA"
        ref="ruleFormA"
        label-width="100px"
        class="demo-ruleForm"
   
      >
        <el-form-item label="学校" prop="schooName">
          <el-input
            v-model="ruleFormA.schooName"
            placeholder=""
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="学院" prop="collegeName">
          <el-input
            v-model="ruleFormA.collegeName"
            placeholder=""
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="上传图片" prop="collegeName" style="text-align: left;;">
            <el-upload
  class="avatar-uploader"
  action="/"
  :http-request="uploadFile"
  :show-file-list="false"
  :on-success="handleAvatarSuccess"
  :before-upload="beforeAvatarUpload">
  <img v-if="imageUrl" :src="imageUrl" class="avatar">
  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
</el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="cgxFun" v-if="xj" type="danger"
            >存入草稿箱</el-button
          > -->
        <el-button @click="dataSet(title)">取 消</el-button>
        <el-button type="primary" @click="dataSet(title)">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
    <script>
import inputIp from "../components/inputIp.vue";

// import Editormd from "./edmitormd.vue";
import common from "./comment.vue";

import wangEditor from "./wangEditor";
//   // 引入markdown文件示例
// import demo from '../assets/demo.md'
export default {
  components: {
    inputIp,
    // Editormd,
    common,
    wangEditor,
  },
  data() {
    return {
      xx: false,
      aa: "没有",
      xxB: true,
      emptyText: "暂无动态",
      logList: [],
      tabId: "",
      rwId: "",
      activeName: "first",
      xj: false,
      cgxValue: false,
      showFList: false,
      upEdit: false,
      czText: "显示操作栏",
      czL: false,
      maxHeight: document.body.offsetHeight - 190,
      //上传后的文件列表
      fileList: [],
      // 允许的文件类型
      fileType: [
        "pdf",
        "doc",
        "docx",
        "xls",
        "xlsx",
        "txt",
        "png",
        "jpg",
        "bmp",
        "jpeg",
        "html",
        "zip",
      ],
      // 运行上传文件大小，单位 M
      fileSize: 50,
      // 附件数量限制
      fileLimit: 5,
      //请求头
      headers: { "Content-Type": "multipart/form-data" },
      isClear: false, //设置为true的时候，这个可以用this.wangEditorDetail=''来替代
      wangEditorDetail: "",
      tabPosition: "left",
      dialogVisibleTag: false,
      tagsCurrent: [],
      content: "",
      currentTitle: "",
      // currentHostname:'',
      lookValue: false,
      loading: false,
      limitVal: 15,
      companyV: "",
      locationV: "",
      dx: "",
      // qb:"全屏视图",
      isLook: true,
      idC: "",
      yz: false,
      hostdevD: true,
      currentPage4: 1,

      company: [],
      locationAry: [],
      dialogFormVisible: false,
      dialogTZR: false,
      checkboxGroup3: [],
      input: "",
      id: "",
      dataSource: [],

      dataSourceHost: [
        { typeName: "", typeZ: "", typeIp: "", typePass: "", typeComm: "" },
      ],
      dataSourceBtoom: [
        { typeP: "", typeN: "", typeC: "", typeG: "", typeB: "" },
      ],
      tableData: [],
      widthS: document.body.clientWidth,
      // addStyle:'',
      ruleFormA: {
        collegeName: "",
        schooName: "",
      },
      total: null,
      showpsw: null,
      rulesA: {},
      title: "编辑",
      fzAry: [],
      addType: "",
      hostdevAry: [],
      companyAry: [],
      // isLook: true,
      stateTop: "计划",
      stateDj: "",
      titleTop: "",
      mycreate: false,
      // yz: false,
      dynamicTags: [],
      multiUploadSize: 5000000, // 大于这个大小的文件使用分块上传(后端可以支持断点续传)
      requestCancelQueue: [], // 请求方法队列（调用取消上传）
      eachSize: 5000000, // 每块文件大小
      currentChunk: 0,    imageUrl: ''
    };
  },
  props: {
    qb: String,
    companyAryD: [],
  },
  watch: {
    companyAryD: {
      handler(val) {
        console.log(val);

        this.init(1, this.limitVal);
      },
    },
  },
  mounted() {
    // this.get_content()
    //   setInterval(()=>{
    //     this.init(this.currentPage4,this.limitVal);
    //   },60000)
    this.init(1, this.limitVal);
    // this.labelFun();

    console.log(this.widthS);
    //   this.hostdevFun();
    //   this.locationFun();
    //   this.companyFun();
    //   this.fzInit();
  },
  methods: {
    handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        // if (!isJPG) {
        //   this.$message.error('上传头像图片只能是 JPG 格式!');
        // }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isLt2M;
      },
    
   

    //上传文件的事件
    uploadFile(item) {
    //   this.$message("文件上传中........");
      //上传文件的需要formdata类型;所以要转
      let FormDatas = new FormData();
      FormDatas.append("file", item.file);
      FormDatas.append("op", "uploadicon");
      FormDatas.append("_id", this._id);
      let _this = this;
      this.$ajax({
        method: "post",
        url: _this.paas,
        headers: _this.headers,
        timeout: 30000,
        data: FormDatas,
      }).then((res) => {
        if (res.data.value == "ok") {
          debugger;
          _this.fileList = res.data.data; //成功过后手动将文件添加到展示列表里
          console.log(res.data.data[0].url)
        _this.imageUrl = res.data.data[0].url
         
        } else {
          _this.$message.error("图片上传失败！");
        }
      });
    },
    //上传成功后的回调
    handleSuccess() {},
    // 删除标签
    closeTagFun(title, index) {
      this.tagsCurrent.splice(index, 1);
      // debugger
      // delete  this.tagsCurrent[index]
      console.log(this.tagsCurrent);
    },
    // 选中标签
    tabFun(id, title, color, index) {
      // let curTag = this.tagsCurrent.indexOf(title);
      if (this.tagsCurrent.length > 0) {
        debugger;
        let temp = true;
        for (let i = 0; i < this.tagsCurrent.length; i++) {
          if (this.tagsCurrent[i].title == title) {
            temp = false;
            break;
          } else {
            temp = true;
          }
        }
        if (temp) {
          this.tagsCurrent.push({ title: title, id: id, color: color });
        } else {
          this.$message("已选中");
        }
      } else {
        this.tagsCurrent.push({ title: title, id: id, color: color });
      }
      console.log(this.tagsCurrent);
    },
   
    rowdbFun(row) {
      this.add(row);
    },
    czFun() {
      if (this.czText == "显示操作栏") {
        this.czText = "关闭操作栏";
        this.czL = true;
        //在执行完跳转页码的数据请求后
        this.$nextTick(() => {
          this.$refs.table.bodyWrapper.scrollTop = 0;
        });
      } else {
        this.czText = "显示操作栏";
        this.czL = false;
      }
    },
    fzInit(Ary) {
      let data = {
        op: "userlist",
        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.fzAry = response.data.data;
            let currentData = response.data.data.data;

            console.log(_this.tableData);
            debugger;
            for (let i = 0; i < Ary.length; i++) {
              Ary[i].managerName = [];
              for (let y = 0; y < _this.fzAry.length; y++) {
                if (Ary[i].executor) {
                  for (let ii = 0; ii < Ary[i].executor.length; ii++) {
                    if (Ary[i].executor[ii] == _this.fzAry[y]._id) {
                      Ary[i].managerName.push(_this.fzAry[y].name);

                      console.log(Ary);
                    }
                  }
                }
                if (Ary[i]._sys_creator) {
                  if (Ary[i]._sys_creator == _this.fzAry[y]._id) {
                    Ary[i]._sys_creatorName = _this.fzAry[y].name;
                    console.log(Ary);
                  }
                }
              }
            }
            console.log(Ary);
            _this.tableData = Ary;
            console.log(_this.tableData);
          } else {
            // _this.tableData = Ary;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loglistFun() {
      let data = {
        op: "loglist",
        _id: this.id,
        // code: this.dx,

        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.task, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.logList = response.data.data;
            // console.log(response.data.data);
            // _this.ruleFormA.seninfo = response.data.data;
            // _this.isLook = false;
            // _this.yz = true;
          } else {
            _this.$message({
              message: response.data.msg,
              type: "warning",
            });
            // _this.isLook = true;
            // _this.yz = false;
            // console.log(_this.isLook);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    indexFun(index) {
      return this.limitVal * (this.currentPage4 - 1) + index + 1;
    },

    change() {
      console.log(7);
      this.$forceUpdate();
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.limitVal = val;
      this.init(1, val);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    companyFunI() {
      this.init(1, this.limitVal);
    },
    locationFunI() {
      this.init(1, this.limitVal);
    },
    locationFunD() {
      this.init(1, this.limitVal);
    },
    resFun() {
      this.input = "";
      this.stateTop = "计划";
      this.mycreate = false;
      this.currentPage4 = 1;
      this.limitVal = 15;
      this.init(this.currentPage4, this.limitVal);
    },
    init(page, limit = this.limitVal) {
      console.log(typeof this.mycreate);
      console.log(typeof JSON.parse(this.mycreate));
      this.currentPage4 = page;
      let data = {
        op: "search",
        school: this.input,
        //   state: this.stateTop,
        //   mycreate: this.mycreate == false ? 0 : 1,
        //   level:this.stateDj,
        page: page,
        limit: limit,
      };
      let _this = this;
      this.$ajax
        .post(this.paas, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.total = response.data.data.recordcount;
            //   _this.tableData = response.data.data.data;
            _this.fzInit(response.data.data.data);
            if (_this.currentPage4 != 1 && _this.tableData.length == 0) {
              // 解决删除最后一页的只有一条数据的情况，那就从上一页开始查询
              _this.currentPage4--;
              _this.init(_this.currentPage4, _this.limitVal);
            }
            // _this.fzInit(response.data.data.data);
          } else if (response.data.value == "sessionerror") {
            _this.$message.error(response.data.msg);
            setTimeout(() => {
              _this.$router.push({ path: "/" });
            }, 2500);
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    add(data) {
      this.dialogFormVisible = true;
      // 编辑
      this.ruleFormA.schooName = data.schooName;
      this.ruleFormA.collegeName = data.collegeName;
      this._id = data._id;
      this.imageUrl=data.file
    },

    typeFun(val) {
      console.log(val);
      if (val == "虚拟机申请" && this.ruleFormA.state == "完成") {
        this.xx = true;
        this.xxB = true;
      } else {
        this.xx = false;
        this.xxB = true;
      }
    },
    del(id, title) {
      let data = {
        op: "finish",
        _id: id,
      };
      let _this = this;
      this.$ajax
        .post(this.task, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.$message({
              message: title + response.data.msg,
              type: "success",
            });

            _this.init(_this.currentPage4, _this.limitVal);
          } else if (response.data.value == "sessionerror") {
            _this.$message.error(response.data.msg);
            setTimeout(() => {
              _this.$router.push({ path: "/" });
            }, 2500);
          } else {
            _this.$message.info({
              message: response.data.msg,
              type: "success",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    quan() {
      if (this.qb == "全屏视图") {
        // this.qb = '还原视图'
        this.$emit("quan", false);
      } else {
        // this.qb = '全屏视图'
        this.$emit("quan", true);
      }
    },
    dataSet() {
      this.dialogFormVisible = false;
      this.init(this.currentPage4, this.limitVal);
    },
    currentP(size) {
      console.log(size);
      this.currentPage4 = size;
      this.init(size, this.limitVal);
    },
    preFun(size) {
      this.init(size, this.limitVal);
    },
    nextFun(size) {
      this.init(size, this.limitVal);
    },
  },
};
</script>
    <style lang="scss" scoped>
.tabAll {
  margin-top: 0px;
  width: 100%;
  overflow: auto;
  float: left;
  /* float:left */
}
.el-checkbox.is-bordered.el-checkbox--small {
  width: 115px;
  float: left;
  margin: 10px;
}
.el-input__inner {
  border: none;
}
.about {
  padding: 10px;
}
.qiehuan {
  min-height: 40px;
  overflow: hidden;
  .qh {
    float: right;
    margin-left: 10px;
  }
  .add {
    float: right;
    margin-bottom: 10px;
  }
  .sou {
    float: left;
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;
  }
}
</style>
    <style>
     .avatar-uploader .el-upload i{
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
 .jx .el-icon-plus:before{
    line-height: 171px;
 }
 
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .jx  .el-upload-dragger{
    background-color: #fff0;
  }
.elAdd .el-tabs__header.is-top {
  padding: 0 10px;
}
.elAdd .el-tabs__nav-scroll {
  margin-top: 0;
}
.el-upload-list {
  display: none;
}
.el-upload {
  text-align: left !important;
}
.about .el-form-item__label {
  /* color: #d4d4d4 !important; */
}
.jx .el-dialog {
  margin-top: 3vh !important;
  width: 50% !important;
}
.el-select {
  width: 100%;
}
.demo-ruleForm{
  overflow: auto;
}
.el-form-item {
  width: 50%;
  display: inline-block;
  float:left;
}

.el-textarea__inner {
  resize: none !important;
}
.min .el-textarea__inner {
  min-height: 150px !important;
}

thead tr th {
  height: 40px;

  /* background-color: #e3e3e3 !important; */
  /* color: #5b5b5b; */
  font-weight: bold;
}
.el-pagination {
  float: right;
  margin-top: 10px;
}
.el-table .el-table__cell {
  padding: 2px 0 !important;
}
.el-table .el-table__cell {
  margin: 0 !important;
}
/* .addFont-color .el-input__inner {
  color: #fff100;
} */
.log li {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.rightNum .el-tabs__content {
  max-height: 88%;
}
@media (max-width: 800px) {
  .el-dialog {
    width: 98% !important;
  }
  .el-form-item {
    width: 100%;
  }
}
</style>
    