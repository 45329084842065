<template>
  <div class="about">
    <!-- <div class="qiehuan">
          <el-button type="warning" size="small" class="qh">切换大屏展示</el-button>
        </div> -->

    <div class="qiehuan">
      <!-- <el-button type="warning" size="small" class="qh">切换大屏展示</el-button> -->

      <!-- <el-button
        type="warning"
        size="small"
        class="add"
        style="margin-left: 10px"
        @click="quan"
        >{{ qb }}</el-button
      >
      <el-tooltip class="item" effect="dark" :content="czText" placement="top-start">
      <el-button type="danger" size="small" class="add" plain @click="czFun" icon="el-icon-setting"></el-button>
      </el-tooltip>
      <el-button
        type="success"
        size="small"
        class="add"
        @click="add('add', '', '添加服务')"
        >添加服务</el-button
      > -->
      <el-tooltip class="item" effect="dark" :content="qb" placement="top-start">
      <el-button
        type="warning"
        size="mini"
        class="add"
        icon="el-icon-monitor"
        
        style="margin-left: 10px"
        @click="quan"
        ></el-button
      >
      </el-tooltip>
      <el-tooltip class="item" effect="dark" :content="czText" placement="top-start">
      <el-button type="danger" size="mini" class="add" plain @click="czFun" icon="el-icon-setting"></el-button>
      </el-tooltip>
       <el-tooltip class="item" effect="dark" content="添加服务" placement="top-start">
        <el-button
        type="success"
        size="mini"
        class="add"
        icon="el-icon-circle-plus-outline"
        @click="add('add', '', '添加服务')"
        ></el-button
      >
    </el-tooltip>

      <p class="sou">
      
  
         
        <el-input
          v-model="input"
          placeholder="请输入名称"
          style="float: left; margin-right: 2px;width: 150px;"
          size="small"
          @change="init(1)"
        ></el-input>
        
        <el-tooltip class="item" effect="dark" content="搜索" placement="top-start">
          <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="init(1)"
      
          ></el-button
        >

      </el-tooltip>
        <el-tooltip class="item" effect="dark" content="重置" placement="top-start">
        <el-button type="danger" size="mini" @click="resFun" icon="el-icon-refresh-left"></el-button>
        </el-tooltip>
      
      </p>
    </div>
    <div style="text-align: left;color:red;">温馨提示：双击列表每行的数据可进入查看、编辑</div>
    <el-table
      :data="tableData"
      border
      ref="table"
      style="width: 100%"
      @row-dblclick="rowdbFun"
      v-loading="loading"
      element-loading-text="安装中"
      element-loading-spinner="el-icon-loading"
    >
      <el-table-column type="index" :index="indexFun" width="50" label="序号" fixed="left" >
      </el-table-column>
      <el-table-column prop="title" label="名称"> 
        
        <template slot-scope="scope">
          
          <el-popover trigger="hover" placement="right">
            <p>
              <div v-html="scope.row.comment"></div>
            </p>
            <div slot="reference" class="name-wrapper">
            {{scope.row.title}}
            </div>
          </el-popover>
        
        </template>
      

      </el-table-column>

      <el-table-column prop="host" label="域名">
        <template slot-scope="scope">
          <span v-for="(item, index) in scope.row.host" :key="index">
            {{ item.name }}
            <span
              :style="
                item.state == '正常' ? 'color:rgb(103, 194, 58)' : 'color:red'
              "
            >
              【{{ item.state }} 】
            </span>
            <br />
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="certdate" label="证书剩余（天）"> 
        <template slot-scope="scope">
          <span v-if="scope.row.certdate <= 30" style="color:red">{{scope.row.certdate}}</span>
          <span v-else>{{scope.row.certdate}}</span>
        </template>
      </el-table-column>
      
      <!-- <el-table-column prop="device_hostname" label="主机名"> </el-table-column>
      <el-table-column prop="device_title" label="所属服务"> </el-table-column> -->
      <!-- <el-table-column prop="device_type" label="服务器类型"> </el-table-column> -->
      <el-table-column prop="device_title" label="所在设备"> 
        <template slot-scope="scope">
          {{scope.row.device_hostname}} - {{ scope.row.device_title }} - {{scope.row.device_type}}

        </template>
      </el-table-column>
      <!-- <el-table-column prop="level" label="服务3"> </el-table-column> -->
      <!-- <el-table-column prop="managerName" label="服务1">
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.managerName" :key="index">
               {{ item }}  {{index == scope.row.managerName.length-1?'':'、'}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="_sys_creatorName" label="服务1"> </el-table-column>
        <el-table-column prop="_sys_createtime" label="服务1"> 
     </el-table-column> -->
      <!-- <el-table-column prop="finishtime" label="服务1"> </el-table-column> -->
      <!-- <el-table-column prop="comment" label="备注"> </el-table-column> -->
      <!-- <el-table-column prop="seninfo" label="敏感信息"> </el-table-column> -->
      <el-table-column fixed="right" label="操作" width="80"   v-if="czL">
        <template slot-scope="scope">
          <i
            class="el-icon-edit"
            @click="add('edit', scope.row, '编辑')"
            style="cursor: pointer; color: #409eff"
          ></i>
          <el-popconfirm
            :title="`确定删除${scope.row.title}？`"
            @confirm="del(scope.row._id, scope.row.title)"
          >
            <i
              class="el-icon-delete"
              slot="reference"
              style="margin-left: 15px; cursor: pointer; color: red"
            ></i>
          </el-popconfirm>

          <!-- <el-popconfirm
              title="确定已完成任务？"
              @confirm="del(scope.row._id, scope.row.title)"
              v-if="scope.row.state != '完成'"
            >
              <i
                class="el-icon-wind-power"
                slot="reference"
                style="margin-left: 15px; cursor: pointer; color: green"
              ></i>
            </el-popconfirm> -->
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="currentP"
      :current-page="currentPage4"
      :page-sizes="[15, 20, 25, 30]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!---编辑/添加-->
    <el-dialog :title="title" :visible.sync="dialogFormVisible">
      <el-form
        :model="ruleFormA"
        :rules="rulesA"
        ref="ruleFormA"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="名称" prop="title">
          <el-input v-model="ruleFormA.title" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="检测标识" prop="checkstr">
          <el-input v-model="ruleFormA.checkstr" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="端口" prop="port">
          <el-input v-model="ruleFormA.port" placeholder=""></el-input>
        </el-form-item>

        <el-form-item label="所属设备" prop="device">
          <el-select v-model="ruleFormA.device" placeholder="" filterable>
            <el-option
              :label="item.hostname"
              :value="item._id"
              v-for="(item, index) in fzAry"
              :key="index"
            >
              <span style="float: left">{{ item.hostname }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.title
              }}</span>
            </el-option>
            <!-- <el-option label="虚拟机" value="虚拟机"></el-option>
              <el-option label="物理机" value="物理机"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="负责人" prop="manager">
          <el-select
            v-model="ruleFormA.manager"
            placeholder=""
            multiple
            filterable
          >
            <el-option
              :label="item.name"
              :value="item._id"
              v-for="(item, index) in fzAryFZR"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="端口" prop="port" style="visibility: hidden">
          <el-input v-model="ruleFormA.port" placeholder=""></el-input>
        </el-form-item>

        <el-form-item label="域名列表" prop="host" style="width: 100%">
          <span style="position: absolute; left: -78px; color: red">* </span>
          <el-button
            type="success"
            @click="handleAddHost"
            size="small"
            style="float: left; margin-bottom: 5px"
            >添加</el-button
          >

          <el-table
            :data="dataSourceHost"
            style="width: 100%"
            max-height="300"
            border
          >
            <el-table-column prop="" label="域名" :resizable="false">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.name"
                  placeholder=""
                  @input="change($event)"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="120"
              fixed="right"
              :resizable="false"
            
            >
              <template slot-scope="scope">
                <a
                  href="javascript:;"
                  @click="() => onDelete(scope.$index, dataSourceHost)"
                  >删除</a
                >
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="备注" prop="comment" style="width: 100%">
          <!-- <Editormd
              :content="ruleFormA.comment"
              @content="contentFun"
            ></Editormd> -->
            <wangEditor
                style="text-align: left"
                :content="ruleFormA.comment"
                @changeData='contentFun'
                :dialogFormVisible="dialogFormVisible"
            
              ></wangEditor>
        </el-form-item>
    
         
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dataSet(title)">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
    <script>
import inputIp from "../components/inputIp.vue";
// import Editormd from "./edmitormd.vue";
import wangEditor from "./wangEditor";
//   // 引入markdown文件示例
// import demo from '../assets/demo.md'
export default {
  components: {
    inputIp,
    wangEditor,
    // Editormd,
  },
  data() {
    return {
      czText: "显示操作栏",
      czL: false,
      maxHeight: document.body.offsetHeight - 190,
      //上传后的文件列表
      fileList: [],
      // 允许的文件类型
      fileType: [
        "pdf",
        "doc",
        "docx",
        "xls",
        "xlsx",
        "txt",
        "png",
        "jpg",
        "bmp",
        "jpeg",
        "html",
        "zip",
      ],
      // 运行上传文件大小，单位 M
      fileSize: 50,
      // 附件数量限制
      fileLimit: 5,
      //请求头
      headers: { "Content-Type": "multipart/form-data" },

      isClear: false, //设置为true的时候，这个可以用this.wangEditorDetail=''来替代
      wangEditorDetail: "",
      content: "",
      currentTitle: "",
      // currentHostname:'',
      lookValue: false,
      loading: false,
      limitVal: 15,
      companyV: "",
      locationV: "",
      dx: "",
      // qb:"全屏视图",
      isLook: true,
      idC: "",
      // yz: false,
      hostdevD: true,
      currentPage4: 1,

      company: [],
      locationAry: [],
      dialogFormVisible: false,
      input: "",
      id: "",
      dataSource: [],
      dataSourceHost: [],
      tableData: [],
      widthS: document.body.clientWidth,
      // addStyle:'',
      ruleFormA: {
        title: "",
        comment: "",
        seninfo: "",
        state: "计划",
        executor: [],
        level: "",
        device: "",
        port: "",
        host: "",
        checkstr: "EduCGRunning",
        manager:''
        
      },
      total: null,
      showpsw: null,
      rulesA: {
        title: [{ required: true, message: "请输入设备名称", trigger: "blur" }],
        state: [{ required: true, message: "请选择状态", trigger: "blur" }],
        //   host: [{ required: true,message: " 4",trigger: "change" }],
        level: [{ required: true, message: "请选择等级", trigger: "change" }],
        executor: [
          { required: true, message: "请选择执行人", trigger: "change" },
        ],
        checkstr: [
          { required: true, message: "请输入检测标识", trigger: "blur" },
        ],
      },
      title: "编辑",
      fzAry: [],
      fzAryFZR:[],
      addType: "",
      hostdevAry: [],
      companyAry: [],
      // isLook: true,
      stateTop: undefined,
      titleTop: "",
      mycreate: false,
      yz: false,
    };
  },
  props: {
    qb: String,
    companyAryD: [],
  },
  watch: {
    companyAryD: {
      handler(val) {
        console.log(val);
        this.init(1, this.limitVal);
      },
    },
  },
  mounted() {
    this.wangEditorDetail = "我是默认值"; //设置富文本框默认显示内容
    // this.get_content()
    //   setInterval(()=>{
    //     this.init(this.currentPage4,this.limitVal);
    //   },60000)
    this.init(1, this.limitVal);
    this.fzInit();
    this.fzInitFZR();
    console.log(this.widthS);
    //   this.hostdevFun();
    //   this.locationFun();
    //   this.companyFun();
    //   this.fzInit();
  },
  methods: {
    //上传文件之前
    beforeUpload(file) {
      if (file.type != "" || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, "").toLowerCase();
        //计算文件的大小
        const isLt5M = file.size / 1024 / 1024 < 50; //这里做文件大小限制
        //如果大于50M
        if (!isLt5M) {
          this.$message("上传文件大小不能超过 50MB!");
          return false;
        }
        //如果文件类型不在允许上传的范围内
        // if (this.fileType.includes(FileExt)) {
        //   return true;
        // } else {
        //   this.$message.error("上传文件格式不正确!");
        //   return false;
        // }
      }
    },
    //上传了的文件给移除的事件，由于我没有用到默认的展示，所以没有用到
    handleRemove() {},
    //这是我自定义的移除事件
    handleClose(i) {
      this.fileList.splice(i, 1); //删除上传的文件
      if (this.fileList.length == 0) {
        //如果删完了
        // this.fileflag = true; //显示url必填的标识
        // this.$set(this.rules.url, 0, {
        //   required: true,
        //   validator: this.validatorUrl,
        //   trigger: "blur",
        // }); //然后动态的添加本地方法的校验规则
      }
    },
    //超出文件个数的回调
    handleExceed() {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！",
      });
      return;
    },
    //上传文件的事件
    uploadFile(item) {
      this.$message("文件上传中........");
      //上传文件的需要formdata类型;所以要转
      let FormDatas = new FormData();
      FormDatas.append("file", item.file);
      FormDatas.append("op", "uploadfile");
      FormDatas.append("_id", this.id);
      let _this = this;
      this.$ajax({
        method: "post",
        url: _this.company,
        headers: _this.headers,
        timeout: 30000,
        data: FormDatas,
      }).then((res) => {
        if (res.data.value == "ok") {
          _this.fileList.push(item.file); //成功过后手动将文件添加到展示列表里
          console.log(_this.fileList);
          let i = _this.fileList.indexOf(item.file);
          _this.fileList[i].id = res.data.fid; //id也添加进去，最后整个大表单提交的时候需要的
          if (_this.fileList.length > 0) {
            //如果上传了附件就把校验规则给干掉
            // this.fileflag = false;
            // this.$set(this.rules.url, 0, "");
          }
          //this.handleSuccess();
        } else {
          _this.$message.error("文件上传失败！");
        }
      });
    },
    //上传成功后的回调
    handleSuccess() {},

    wangEditorChange(val) {
      console.log("我是富文本的内容" + val);
    },

    rowdbFun(row) {
      this.add("edit", row, "编辑");
    },
    handleAddHost() {
      const { countHost, dataSourceHost } = this;
      const newData = { name: "" };
      this.dataSourceHost = [...dataSourceHost, newData];
      console.log(this.dataSourceHost);
      this.countHost = countHost + 1;
    },
    onDelete(index, rows) {
      rows.splice(index, 1);
      // this.dis = false;
    },
    fzInitFZR() {
      let data = {
        op: "userlist",
        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.fzAryFZR = response.data.data;
    
            console.log(_this.tableData);
          } else {
            // _this.tableData = Ary;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    czFun() {
      if (this.czText == "显示操作栏") {
        this.czText = "关闭操作栏";
        this.czL = true;
        //在执行完跳转页码的数据请求后
        this.$nextTick(() => {
          this.$refs.table.bodyWrapper.scrollTop = 0;
        });
      } else {
        this.czText = "显示操作栏";
        this.czL = false;
      }
    },
    fzInit() {
      let data = {
        op: "devlist",
        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.fzAry = response.data.data;
            //   let currentData = response.data.data.data;

            console.log(_this.tableData);
            //   debugger;
            //   for (let i = 0; i < Ary.length; i++) {
            //     Ary[i].managerName = [];
            //     for (let y = 0; y < _this.fzAry.length; y++) {
            //       if (Ary[i].executor) {
            //         for (let ii = 0; ii < Ary[i].executor.length; ii++) {
            //           if (Ary[i].executor[ii] == _this.fzAry[y]._id) {
            //             Ary[i].managerName.push(_this.fzAry[y].name);

            //             console.log(Ary);
            //           }
            //         }
            //       }
            //       if(Ary[i]._sys_creator){
            //           if (Ary[i]._sys_creator == _this.fzAry[y]._id) {
            //             Ary[i]._sys_creatorName= _this.fzAry[y].name
            //             console.log(Ary);
            //           }

            //       }
            //     }
            //   }
            //   console.log(Ary);
            //   _this.tableData = Ary;
            console.log(_this.tableData);
          } else {
            // _this.tableData = Ary;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 提交验证码
    yanz() {
      // console.log(id)
      // 调取接口
      // 校验通过
      if (this.dx.trim() == "") {
        this.$message({
          message: "请输入验证码！",
          type: "warning",
        });
        return;
      }
      let data = {
        op: "getseninfo",
        _id: this.id,
        code: this.dx,

        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            // console.log(response.data.data);
            _this.ruleFormA.seninfo = response.data.data;
            _this.isLook = false;
            _this.yz = true;
          } else {
            _this.$message({
              message: response.data.msg,
              type: "warning",
            });
            // _this.isLook = true;
            _this.yz = false;
            console.log(_this.isLook);
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      console.log(this.yz);
    },
    lookFun() {
      // 校验通过
      let data = {
        op: "getvfycode",
        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          _this.lookValue = false;
          if (response.data.value == "ok") {
            // console.log(response.data.data);
            _this.$message({
              message: response.data.msg,
              type: "success",
            });
            _this.isLook = false;
          } else if (response.data.value == "fail") {
            _this.isLook = false;
          } else {
            _this.$message({
              message: response.data.msg,
              type: "warning",
            });
            _this.isLook = true;
            // console.log( _this.isLook)
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    contentFun(val) {
      this.ruleFormA.comment = val;
    },
    indexFun(index) {
      return this.limitVal * (this.currentPage4 - 1) + index + 1;
    },

    change() {
      console.log(7);
      this.$forceUpdate();
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.limitVal = val;
      this.init(1, val);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    companyFunI() {
      this.init(1, this.limitVal);
    },
    locationFunI() {
      this.init(1, this.limitVal);
    },
    resFun(){
      this.input = '';
      this.currentPage4 = 1;
      this.limitVal = 15;
      this.init( this.currentPage4, this.limitVal)
    },
    init(page, limit = this.limitVal) {
      this.currentPage4 = page;
      let data = {
        op: "search",
        title: this.input,
        //   state: this.stateTop,
        //   mycreate: this.mycreate,

        page: page,
        limit: limit,
      };
      let _this = this;
      this.$ajax
        .post(this.server, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.total = response.data.data.recordcount;
            _this.tableData = response.data.data.data;
            //   _this.fzInit(response.data.data.data);
            if (_this.currentPage4 != 1 && _this.tableData.length == 0) {
              // 解决删除最后一页的只有一条数据的情况，那就从上一页开始查询
              _this.currentPage4--;
              _this.init(_this.currentPage4, _this.limitVal);
            }
            // _this.fzInit(response.data.data.data);
          } else if (response.data.value == "sessionerror") {
            _this.$message.error(response.data.msg);
            setTimeout(() => {
              _this.$router.push({ path: "/" });
            }, 2500);
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    handleClick(row) {
      console.log(row);
    },

    onDelete(index, rows) {
      rows.splice(index, 1);
      // this.dis = false;
    },
    //   changeFun(id) {
    //     console.log(id);
    //     this.valueS = id;
    //   },
    look(seninfo) {
      console.log(seninfo);
      if (seninfo === false) {
        this.lookValue = true;
      } else {
        this.ruleFormA.seninfo = seninfo;
        this.isLook = false;
        this.yz = true;
      }
    },
    add(type, data, title) {
      if (this.$refs.ruleFormA !== undefined) {
        this.$refs.ruleFormA.resetFields();
        console.log(9);
      }
      this.dialogFormVisible = true;

      this.dx = "";

      this.title = title;
      this.addType = type;
      this.isLook = true;
      this.yz = false;
      console.log(data);

      if (data != "") {
        // 编辑
        this.ruleFormA.title = data.title;
        this.currentTitle = data.title;

        this.ruleFormA.comment = data.comment;
        this.ruleFormA.port = data.port;
        this.ruleFormA.device = data.device;
        //   this.ruleFormA.executor = data.executor;
        //   this.ruleFormA.seninfo = data.seninfo;
        // this.content = data.seninfo
        //   if (data.seninfo === false) {
        //   this.ruleFormA.seninfo = false;
        // } else {
        //   this.ruleFormA.seninfo = data.seninfo;
        // }
        if (data.host && data.host != "") {
          this.dataSourceHost = data.host;
        } else {
          this.dataSourceHost = [];
        }
        // 附件
        // this.fileList = data.fileList
        this.id = data._id;
        this.ruleFormA.checkstr = data.checkstr;
        this.ruleFormA.manager = data.manager;
        // 宿主机
      } else {
        this.ruleFormA.manager = "";
        this.ruleFormA.checkstr = "EduCGRunning";
        this.fileList = [];
        this.dataSource = [];
        this.dataSourceHost = [];
        //   this.dataSourceHost = [];
        this.id = "";
        this.ruleFormA.title = "";
        this.ruleFormA.port = data.port;
        this.ruleFormA.device = data.device;
        this.ruleFormA.comment = "";
      
        //   this.ruleFormA.state = "计划";
        //   this.ruleFormA.comment = "";
        //   this.ruleFormA.executor = [];
      }
      // console.log(this.$refs.ruleFormA)
    },

    //   typeFun(val) {
    //     console.log(val);
    //     if (val == "虚拟机") {
    //       this.hostdevD = false;
    //     } else {
    //       this.hostdevD = true;
    //       this.ruleFormA.hostdev = "";
    //     }
    //   },
    del(id, title) {
      let data = {
        op: "del",
        _id: id,
      };
      let _this = this;
      this.$ajax
        .post(this.server, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.$message({
              message: title + response.data.msg,
              type: "success",
            });

            _this.init(_this.currentPage4, _this.limitVal);
          } else if (response.data.value == "sessionerror") {
            _this.$message.error(response.data.msg);
            setTimeout(() => {
              _this.$router.push({ path: "/" });
            }, 2500);
          } else {
            _this.$message.info({
              message: response.data.msg,
              type: "success",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 获取设备列表
    // hostdevFun() {
    //   let data = {
    //     op: "devlist",
    //     // title: this.input,
    //   };
    //   let _this = this;
    //   this.$ajax
    //     .post(this.url + "/device.do", _this.Qs.stringify(data))
    //     .then(function (response) {
    //       console.log(response);
    //       if (response.data.value == "ok") {
    //         console.log(response.data.data);
    //         _this.dataSourceHost = response.data.data;
    //       } else {
    //       }
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },

    quan() {
      if (this.qb == "全屏视图") {
        // this.qb = '还原视图'
        this.$emit("quan", false);
      } else {
        // this.qb = '全屏视图'
        this.$emit("quan", true);
      }
    },
    dataSet() {
      this.$refs.ruleFormA.validate((valid) => {
        if (valid) {
          let currentName = [];

          if (
            this.addType == "edit" &&
            this.currentTitle.trim() == this.ruleFormA.title.trim()
          ) {
            // 名称没变就不提交字段
            currentName = [];
          } else {
            currentName = this.ruleFormA.title.trim();
          }
          if (this.dataSourceHost.length == 0) {
            this.$message("请添加并输入域名");
            return;
          }

          let data = {
            op: this.addType,
            title: currentName,
            manager: this.ruleFormA.manager,
            _id: this.id,
            //   seninfo:this.ruleFormA.seninfo,
            //   title
            port: this.ruleFormA.port,
            comment: this.ruleFormA.comment,
            device: this.ruleFormA.device,
            host: this.dataSourceHost.length == 0 ? "" : this.dataSourceHost,
            checkstr: this.ruleFormA.checkstr,
          };
          let _this = this;
          this.$ajax
            .post(this.server, _this.Qs.stringify(data))
            .then(function (response) {
              console.log(response);
              if (response.data.value == "ok") {
                _this.$message({
                  message: response.data.msg,
                  type: "success",
                });
                _this.dialogFormVisible = false;
                if (_this.addType == "add") {
                  _this.currentPage4 = 1;
                  _this.init(1, _this.limitVal);
                } else {
                  _this.init(_this.currentPage4, _this.limitVal);
                }

                // _this.hostdevFun();
              } else if (response.data.value == "sessionerror") {
                _this.$message.error(response.data.msg);
                setTimeout(() => {
                  _this.$router.push({ path: "/" });
                }, 2500);
              } else {
                _this.$message.error(response.data.msg);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
        }
      });
    },
    currentP(size) {
      console.log(size);
      this.currentPage4 = size;
      this.init(size, this.limitVal);
    },
    preFun(size) {
      this.init(size, this.limitVal);
    },
    nextFun(size) {
      this.init(size, this.limitVal);
    },
  },
};
</script>
    <style lang="scss" scoped>
.el-input__inner {
  border: none;
}
.about {
  padding: 10px;
}
.qiehuan {
  min-height: 40px;
  overflow: hidden;
  .qh {
    float: right;
    margin-left: 10px;
  }
  .add {
    float: right;
    margin-bottom: 10px;
  }
  .sou {
    float: left;
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;
  }
}
</style>
    <style>
.tabAll {
  margin-top: 44px;
  overflow: auto;
  /* float:left */
}
.tabAll .el-tag + .el-tag {
  margin-left: 0;
}
.about .el-form-item__label {
  /* color: #d4d4d4 !important; */
}
.el-dialog {
  margin-top: 3vh !important;
  width: 60% !important;
}
.el-select {
  width: 100%;
}
.el-form-item {
  width: 50%;
  display: inline-block;
}

.el-textarea__inner {
  resize: none !important;
}
.min .el-textarea__inner {
  min-height: 150px !important;
}
.w-e-toolbar,
.w-e-text-container,
.w-e-menu-panel {
  background-color: transparent !important;
}
.wangeditor,
.wangeditor .toolbar {
  border: 1px solid #6d6d6d;
}

thead tr th {
  height: 40px;

  /* background-color: #e3e3e3 !important; */
  /* color: #5b5b5b; */
  font-weight: bold;
}
.el-pagination {
  float: right;
  margin-top: 10px;
}
.el-table .el-table__cell {
  padding: 2px 0 !important;
}
.el-table .el-table__cell {
  margin: 0 !important;
}
@media (max-width: 800px) {
  .el-dialog {
    width: 98% !important;
  }
  .el-form-item {
    width: 100%;
  }
}
</style>
    