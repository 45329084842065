<template>
  <div class="box">
    <div class="box-from">
      <div class="zc">
    【注册】  已有账号？<span @click="goReg" class="go">去登录</span>
      </div>
      <el-steps :active="current"  finish-status="success" align-left style="margin-bottom:30px;">
          <el-step title="绑定手机"></el-step>
          <el-step title="账号信息" ></el-step>
          <el-step title="设置密码" ></el-step>
        </el-steps>
        <regCom
          class="reg-com"
          @submitFun="submitFun(arguments)"
          v-show="current == 0"
        ></regCom>
        <regDe
          class="reg-com reg-reg"
          @submitFun="submitFun(arguments)"
          v-show="current == 1"
        ></regDe>
        <regPass
          class="reg-com"
          @submitFun="submitFun(arguments)"
          v-show="current == 2"
        ></regPass>

    </div>
  </div>
</template>
  <script>
  //注册
import regCom from "../components/regCom.vue";
import regDe from "../components/regDe.vue";
import regPass from "../components/regPass.vue";
export default {
  data() {
    return {
        active:1,
        current: 0,

    };
  },
  components: {
    //注册
    regCom,
    regDe,
    regPass,
  },
  methods: {
    submitFun(val) {
       
       console.log(val);
       this.current = val[0];
     },

    goReg() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>
  <style lang="scss" scoped>
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #fff;
$cursor: #fff;
.box {
  background: $bg;
  height: 100vh;
  padding: 10px;
  .zc {
    margin-bottom: 20px;
    color: #7cc5fe;
  text-align: right;
  }
  .box-from {
    width: 400px;
    margin: 100px auto 50px;
    // box-shadow: 0px 0px 19px 3px rgb(104 166 210);
    border-radius: 10px;
    padding: 10px 10px;
    // height:240px
  }
}
</style>
  <style lang="scss">
.el-form-item__label {
  // color: #fff !important;
}
.el-steps--horizontal{text-align: left;}
 .el-step__title.is-process{
  color: #bcdfff !important
}

</style>