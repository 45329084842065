<template>
  <a-form-model
    ref="ruleForm"
    :model="form"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <a-form-model-item
      ref="name"
      label="用户名"
      prop="name"
      :labelCol="{ span: 24, offset: 0 }"
      labelAlign="left"
    >
      <a-input placeholder="请输入您的用户名" v-model="form.name">
        <a-icon slot="prefix" type="user"
      /></a-input>
    </a-form-model-item>

    <a-form-model-item
      ref="realName"
      label="真实姓名"
      prop="realName"
      :labelCol="{ span: 24, offset: 0 }"
      labelAlign="left"
    >
      <a-input
        placeholder="请输入您的真实姓名"
        v-model="form.realName"
        id="realName"
      >
        <a-icon slot="prefix" type="user"
      /></a-input>
    </a-form-model-item>

 



    <a-form-model-item
      ref="email"
      label="邮箱"
      prop="email"
      :labelCol="{ span: 24, offset: 0 }"
      labelAlign="left"
    >
      <a-input placeholder="请输入您的邮箱" v-model="form.email">
        <a-icon slot="prefix" type="folder"
      /></a-input>
    </a-form-model-item>
    <a-form-model-item
      label="地址"
      prop="addr"
      ref="addr"
      :labelCol="{ span: 24, offset: 0 }"
      labelAlign="left"
    >
      <a-input placeholder="请输入地址" v-model="form.addr">
        <a-icon slot="prefix" type="bank" />
      </a-input>
    </a-form-model-item>
 
    <a-form-model-item>
      <a-button type="primary" @click="onSubmitReg" style="width: 100%">
        下一步
      </a-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      visiblereg: false,
      codeBtn: true,
      codeText: "发送验证码",
      countdown: 60,
      labelCol: { span: 4 },
      wrapperCol: { span: 24 },
      cardS: false,
      form: {
        // name: "",
        addr: "",
        // code: "",
        // phone: "",
        name: "",
        email: "",
        school: "",

        sex: undefined,
        type: undefined,
        card: "",
        date: "",
        realName: "",
      },
      rules: {
        // school: [
        //   {
        //     validator: this.checkSchool,
        //     trigger: "blur",
        //   },
        // ],
      

        type: [
          { required: true, message: "请选择您的证件类型", trigger: "change" },
          {
            validator: this.checkType,
            trigger: "change",
          },
        ],
        card: [
          { required: true, message: "请输入您的证件号", trigger: "blur" },
          {
            validator: this.checkCard,
            trigger: "blur",
          },
        ],
        date: [
          { required: true, message: "请选择您的生日", trigger: "change" },
        ],
        name: [
          {
            required: true,
            message: "请输入您的用户名",
            trigger: "blur",
          },
          {
            min: 2,
            max: 20,
            message: "请输入2-20个字符",
            trigger: "blur",
          },
          {
            validator: this.userName,
            trigger: "blur",
          },
        ],
        realName: [
          {
            required: true,
            message: "请输入您的真实姓名",
            trigger: "blur",
          },
          {
            min: 1,
            max: 20,
            message: "请输入1-20个字符",
            trigger: "blur",
          },
      
        ],
        email: [
          {
            validator: this.checkEmail,
            trigger: "blur",
          },
        ],
        // phone: [
        //   {
        //     required: true,
        //     message: "请输入您的手机号",
        //     trigger: "blur",
        //   },
        //   {
        //     validator: this.checkGrade,
        //     trigger: "blur",
        //   },
        // ],
        // code: [
        //   {
        //     required: true,
        //     message: "请输入您的验证码",
        //     trigger: "blur",
        //   },
        //   {
        //     min: 1,
        //     max: 6,
        //     message: "请输入1-6个字符",
        //     trigger: "blur",
        //   },
        // ],
      },
    };
  },
  methods: {
    openNotification(description) {
      this.$notification.open({
        message: "证件类型为身份证时",
        description: description,
        icon: <a-icon type="close-circle" style="color:red" />,
      });
    },
    onSubmitReg() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {

          window.localStorage.setItem(
            "anjing-useranjing-nameNew",
            this.form.name
          );
          window.localStorage.setItem("anjing-emailNew", this.form.email);
          window.localStorage.setItem("anjing-schoolNew", this.form.school);
          window.localStorage.setItem("anjing-nameNew", this.form.realName);
          window.localStorage.setItem("anjing-addr", this.form.addr);
          // window.localStorage.setItem("anjing-cardtypeNew", this.form.type);
          // window.localStorage.setItem("anjing-cardidNew", this.form.card);
          // window.localStorage.setItem("anjing-sexNew", this.form.sex);
          // window.localStorage.setItem(
          //   "anjing-birthdayNew",
          //   moment(this.form.date).format("YYYY-MM-DD")
          // );
          this.$emit("submitFun", 2, 0, 1);
        } else {
          console.log("error submit!!");
          this.current = 0;
          return false;
        }
      });
    },

    checkEmail(rule, value, callback) {
      console.log(value);
      if (value != "") {
        let structRight = RegExp(
          /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
        ).test(value);
        if (!structRight) {
          callback(new Error("请填写正确的邮箱"));
          // this.codeBtn = true;
          // this.phoneCode = true;
        } else {
          let data = {
            op: "checkemail",
            email: this.form.email,
          };
          let _this = this;
          this.$ajax
            .post(this.url + "/new_registration.do", _this.Qs.stringify(data))
            .then(function (response) {
              console.log(response);
              if (response.data.value == "ok") {
                callback();
              } else {
                callback(new Error(response.data.msg));
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      } else {
        callback();
      }
    },
    userName(rule, value, callback) {
     
      let structRight = RegExp(
      	/^\d+$|^[a-zA-Z]+$/g

      ).test(value);
      if (!structRight) {
        callback(new Error("只能输入英文或者数字!"));
      } else {
        callback();
        let data = {
        op: "checkeusername",
        username: this.form.name,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/new_registration.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            callback();
          } else {
            callback(new Error(response.data.msg));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      }


      
    },
  

  
  },
};
</script>
<style scoped>
/deep/.add .ant-col-17 {
  width: 100%;
}
.codeQ {
  color: #69a4fa;
  cursor: pointer;
  float: right;
}
/* @media (min-width: 800px) {
  /deep/ .ant-form-item {
    width: 48%;
    display: inline-block;
    margin: 10px 1%;
  }
} */
/deep/ .ant-form-item-label > label{
    color:#fff;
}
</style>