<template>
    <div class="box">
      <div class="box-from">
        <div class="zc">
        【 忘记密码 】 已有账号？<span @click="goReg" class="go">去登录</span>
        </div>
        <el-steps :active="currentPass"  finish-status="success" align-center>
          <el-step title="确认账号"></el-step>
          <el-step title="验证方式"></el-step>
          <el-step title="重置密码"></el-step>
        </el-steps>
        <passPhone @currentFun="currentFun(arguments)"></passPhone>
  
      </div>
    </div>
  </template>
    <script>
    //注册
  import passPhone from "../components/passPhone.vue";

  export default {
    data() {
      return {
          active:1,
          current: 0,
  
      };
    },
    components: {
      //注册
    //   regCom,
    //   regDe,
    passPhone,
    },
    methods: {
      submitFun(val) {
         
         console.log(val);
         this.current = val[0];
       },
  
      goReg() {
        this.$router.push({ path: "/" });
      },
    },
  };
  </script>
    <style lang="scss" scoped>
  /* 修复input 背景不协调 和光标变色 */
  /* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */
  
  $bg: #283443;
  $light_gray: #fff;
  $cursor: #fff;
  .box {
    background: $bg;
    height: 100vh;
    padding: 10px;
    .zc {
      margin-bottom: 20px;
      color: #7cc5fe;
  text-align: right;
    }
    .box-from {
      width: 400px;
      margin: 100px auto 50px;
      // box-shadow: 0px 0px 19px 3px rgb(104 166 210);
      border-radius: 10px;
      padding: 10px 10px;
      // height:240px
    }
  }
  </style>
    <style scoped>
  .el-form-item__label {
    /* color: #fff !important; */
  }
  .el-steps--horizontal{text-align: left;}
   .el-step__title.is-process{
    color: #bcdfff !important
  }
  .ant-form-item-label > label{
    color:#fff;
  }
  
  </style>