<template>
  <div class="about">
    <!-- <div class="qiehuan">
        <el-button type="warning" size="small" class="qh">切换大屏展示</el-button>
      </div> -->

    <div class="qiehuan">
      <!-- <el-button type="warning" size="small" class="qh">切换大屏展示</el-button> -->

     
      <el-tooltip class="item" effect="dark" :content="qb" placement="top-start">
      <el-button
        type="warning"
        size="mini"
        class="add"
        icon="el-icon-monitor"
        
        style="margin-left: 10px"
        @click="quan"
        ></el-button
      >
      </el-tooltip>
      <el-tooltip class="item" effect="dark" :content="czText" placement="top-start">
      <el-button type="danger" size="mini" class="add" plain @click="czFun" icon="el-icon-setting"></el-button>
      </el-tooltip>
       <el-tooltip class="item" effect="dark" content="添加交换机设备" placement="top-start">
        <el-button
        type="success"
        size="mini"
        class="add"
        icon="el-icon-circle-plus-outline"
        @click="add('adddev', '', '添加交换机设备')"
        ></el-button
      >
    </el-tooltip>
    <el-tooltip class="item" effect="dark" content="监控" placement="top-start">
        <!-- <el-button
        type="warning"
        plain
        size="mini"
        class="add"
        icon="el-icon-video-camera"
        @click="goLink"
        >
        
        </el-button
      > -->
      <a-button type="primary"  
      plain
        size="small"
        class="add"
        icon="line-chart"
        style="    width: 44px;height: 32px;"
        @click="goLink"/>

    </el-tooltip>
    

      <p class="sou">
        <el-select
          v-model="locationV"
          filterable
          placeholder="请选择设备位置"
          @change="locationFunI"
          size="small"
          style="width: 200px; float: left; margin-right: 5px"
        >
          <el-option label="请选择设备位置" value=""></el-option>
          <el-option
            :label="item.title"
            :value="item._id"
            v-for="(item, index) in locationAry"
            :key="index"
          ></el-option>
        </el-select>

        <el-select
          v-model="companyV"
          filterable
          @change="companyFunI"
          placeholder=""
          size="small"
          style="width: 200px; float: left; margin-right: 30px"
        >
          <el-option label="请选择所属单位" value=""></el-option>
          <el-option
            :label="item.title"
            :value="item._id"
            v-for="(item, index) in companyAry"
            :key="index"
          >
          </el-option>
        </el-select>

        <!-- <el-select
            v-model="typeV"
            filterable
            placeholder=""
            @change="typeI"
            size="small"
            style="width: 20%; float: left; margin-right: 30px"
          >
            <el-option label="请选择类型" value=""></el-option>
  
            <el-option label="虚拟机" value="虚拟机"></el-option>
            <el-option label="物理机" value="物理机"></el-option>
         
          </el-select> -->
        <el-select
          v-model="valueS"
          filterable
          placeholder=""
          @change="changeFun"
          size="small"
          style="width: 150px; float: left; margin-right: 2px"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-input
          v-model="input"
          placeholder="请输入对应检索项内容"
          style="width: 150px; float: left; margin-right: 2px"
          size="small"
          @change="init(1)"
        ></el-input>
      
        <el-tooltip class="item" effect="dark" content="搜索" placement="top-start">
        <el-button type="primary" size="mini" @click="init(1)" icon="el-icon-search"></el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="重置" placement="top-start">
        <el-button type="danger" size="mini" @click="resFun" icon="el-icon-refresh-left"></el-button>
        </el-tooltip>
      </p>
    </div>
    <div style="text-align: left;color:red;">温馨提示：双击列表每行的数据可进入查看、编辑</div>
    <el-table
      ref="table"
      :data="tableData"
      border
      @row-dblclick="rowdbFun"
      @cell-mouse-enter="cellFun"
      style="width: 100%"
      v-loading="loading"
      element-loading-text="安装中"
      element-loading-spinner="el-icon-loading"
    >
      <el-table-column type="index" :index="indexFun" width="50" label="序号" fixed="left">
      </el-table-column>
      <el-table-column prop="title" label="设备编号">
        <!-- <template slot-scope="scope">
          <el-popover trigger="hover" placement="right">
            <p
              v-if="scope.row.hostname != scope.row.realhostname"
              style="color: rgb(204, 88, 18)"
            >
              【 当前主机名与实际设备主机名不一致，实际设备主机名为：<span
                style="color: #fff"
                >{{ scope.row.realhostname }}</span
              >
              。可在操作中同步设置主机名到设备中 】
         
            </p>
            <p>
              设备名称：<span class="fk">{{ scope.row.title }}</span>
            </p>
            <p>
              ip： <span class="fk">{{ scope.row.ip }}</span>
            </p>
            <P
              >upTime：
              <span class="fk">{{
                scope.row.uptime ? scope.row.uptime : "暂无"
              }}</span></P
            >



            <div
              slot="reference"
              class="name-wrapper"
              style="font-weight: bold; color: rgb(6 134 255)"
            >
              {{ scope.row.hostname
              }}<i
                v-if="scope.row.hostname != scope.row.realhostname"
                class="el-icon-warning"
                style="color: rgb(204 88 18); margin-left: 5px"
              ></i>
            </div>
          </el-popover>
        </template> -->
      </el-table-column>


      <el-table-column prop="type" label="型号">
        <!-- <template slot-scope="scope">
          <div v-if="scope.row.type == '物理机'">
            <el-popover
              trigger="click"
              placement="right"
              @show="showW(scope.row._id)"
            >
              <div v-if="wlJAry.length > 0">
                <p>所关联的虚拟机：</p>

                <p v-for="(item, index) in wlJAry" :key="index">
                  {{ item.title
                  }}<span style="color: #625f5f; margin-left: 15px">{{
                    item.hostname
                  }}</span>
                </p>
              </div>
              <p v-else>所关联的虚拟机：暂无</p>
              <div
                slot="reference"
                class="name-wrapper"
                style="font-weight: bold; color: rgb(6 134 255)"
              >
                {{ scope.row.type }}
              </div>
            </el-popover>
          </div>
          <div v-else>
            <div slot="reference" class="name-wrapper">
              {{ scope.row.type }}
            </div>
          </div>
        </template> -->
      </el-table-column>
      <el-table-column prop="portnum" label="端口数量" width="100"> </el-table-column>
      <el-table-column prop="portrate" label="端口速率" width="100"> </el-table-column>
      <el-table-column prop="thruput" label="吞吐量" width="100"> </el-table-column>

      <el-table-column prop="_sys_label" label="标签" width="150">
        <template slot-scope="scope">
          <span v-for="(tag, index) in scope.row._sys_label" :key="index">
            {{ tag.title
            }}<span v-if="index != scope.row._sys_label.length - 1">、</span>
          </span>
        </template>
      </el-table-column>

      <el-table-column prop="start_time" label="开始时间" fixed="right"> </el-table-column>
      <el-table-column prop="end_time" label="结束时间" fixed="right"> </el-table-column>
<!---v-if="czL"-->
      <el-table-column fixed="right" label="操作" width="80"  v-if="czL">
        <template slot-scope="scope">
          <i
            class="el-icon-edit"
            @click="add('editdev', scope.row, '编辑')"
            style="cursor: pointer; color: #409eff;margin-right:15px;"
          ></i>
          <el-popconfirm
            :title="`确定删除${scope.row.title}？`"
            @confirm="del(scope.row._id, scope.row.title)"
          >
            <i
              class="el-icon-delete"
              slot="reference"
              style="cursor: pointer; color: red"
            ></i>
          </el-popconfirm>

          <!-- <i
            class="el-icon-refresh"
            @click="refresh(scope.row._id)"
            style="margin-left: 15px; cursor: pointer; color: #67c23a"
          ></i>
          <i
            class="el-icon-zoom-in"
            @click="des(scope.row)"
            style="cursor: pointer; color: rgb(163 34 238); margin-left: 15px"
          ></i>
     
          <el-popconfirm
            :title="`确定同步主机名？`"
            @confirm="zjName(scope.row._id, scope.row.realhostname)"
            v-if="scope.row.hostname != scope.row.realhostname"
          >
            <i
              class="el-icon-sort"
              slot="reference"
              style="margin-left: 15px; cursor: pointer; color: #409eff"
            ></i>
          </el-popconfirm>


          <a-icon
            type="code"
            style="margin-left: 15px; cursor: pointer; color: rgb(1 166 201)"
            v-if="scope.row.ssh != ''"
            @click="goNewFun(scope.row.ssh)"
          />

          <a-tooltip>
            <template slot="title"> 安装SNMP </template>
            <a-icon
              v-if="scope.row.snmp == ''"
              style="margin-left: 15px; cursor: pointer; color: orange"
              type="tool"
              @click="anmpFun(scope.row._id)"
            />
          </a-tooltip> -->
     
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="currentP"
      :current-page="currentPage4"
      :page-sizes="[15, 20, 25, 30]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!---编辑/添加-->
    <el-dialog :title="title" :visible.sync="dialogFormVisible">
      <el-form
        :model="ruleFormA"
        :rules="rulesA"
        ref="ruleFormA"
        label-width="100px"
        class="demo-ruleForm"
      >
        <!-- <div class="title-container">
            <h3 class="title">登录</h3>
          </div> -->

        <el-form-item label="设备编号" prop="title">
          <el-input v-model="ruleFormA.title" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="ip地址" prop="ip">
          <el-input v-model="ruleFormA.ip" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="型号" prop="type">
          <el-input v-model="ruleFormA.type" placeholder=""></el-input>
          <!-- <inputIp @change="changeIp" @getIpVal="getIpVal" :value="ruleFormA.ip"></inputIp> -->
        </el-form-item>
        <el-form-item label="端口数量" prop="portnum">
          <el-input v-model="ruleFormA.portnum" placeholder=""></el-input>
        </el-form-item>

        <el-form-item label="端口速率" prop="portrate">
          <el-input v-model="ruleFormA.portrate" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="吞吐量" prop="thruput">
          <el-input v-model="ruleFormA.thruput" placeholder=""></el-input>
        </el-form-item>

        <!-- <el-form-item label="用途" prop="use">
            <el-input
              v-model="ruleFormA.use"
              placeholder="请输入用途"
              type="textarea"
              class="addStyle"
            ></el-input>
          </el-form-item> -->
        <!-- <el-form-item label="类型" prop="type">
            <el-input v-model="ruleFormA.type" placeholder="请输入类型"></el-input>
          </el-form-item> -->
      
          <el-form-item label="负责人" prop="manager">
          <el-select v-model="ruleFormA.manager" placeholder="" filterable multiple>
            <el-option
              :label="item.name"
              :value="item._id"
              v-for="(item, index) in fzAry"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备位置" prop="location">
          <el-select v-model="ruleFormA.location" filterable placeholder="">
            <el-option
              :label="item.title"
              :value="item._id"
              v-for="(item, index) in locationAry"
              :key="index"
            ></el-option>
            <!-- <el-option label="虚拟机" value="虚拟机"></el-option>
              <el-option label="物理机" value="物理机"></el-option> -->
          </el-select>
        </el-form-item>

        <el-form-item label="所属单位" prop="company">
          <el-select v-model="ruleFormA.company" filterable placeholder="">
            <el-option
              :label="item.title"
              :value="item._id"
              v-for="(item, index) in companyAry"
              :key="index"
            ></el-option>
    
          </el-select>
        </el-form-item>

   
        <el-form-item label="开始时间" prop="start_time">
          <el-date-picker
            v-model="ruleFormA.start_time"
            type="date"
            placeholder=""
            style="width: 100%"
            format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="end_time">
          <el-date-picker
            v-model="ruleFormA.end_time"
            type="date"
            placeholder=""
            style="width: 100%"
            format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
      
        <el-form-item label="备注" prop="comment">
          <el-input
            v-model="ruleFormA.comment"
            placeholder=""
            type="textarea"
            class="addStyle"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item
          label="负责人"
          prop="manager"
          style="visibility: hidden"
          v-if="widthS > 800"
        >
          <el-select v-model="ruleFormA.manager" placeholder="请选择负责人">
            <el-option
              :label="item.name"
              :value="item._id"
              v-for="(item, index) in fzAry"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item> -->
  
        <el-form-item
          label="标签"
          style="width: 100%"
          class="tags"
          prop="_sys_label"
        >
          <template>
            <el-button
              type="primary"
              size="small"
              style="float: left; margin-bottom: 5px"
              @click="setTagFun"
              >设置标签</el-button
            >
            <div
              style="width: 100%; border: 1px solid #626262; overflow: hidden"
            >
              <div style="float: left; padding: 5px">
                <div v-if="tagsCurrent.length > 0">
                  当前选中：<el-tag
                    v-for="tag in tagsCurrent"
                    :key="tag.title"
                    :style="`color:${tag.color}`"
                  >
                    {{ tag.title }}
                  </el-tag>
                </div>
                <div v-else>
                  当前选中标签：<span style="color: red">暂无</span>
                </div>
              </div>
            </div>
          </template>
        </el-form-item>
        <el-form-item label="IP列表" prop="account" style="width: 100%">
          <!-- <el-button size="small" @click="open">展开</el-button> -->
          <!-- <i class="el-icon-circle-plus-outline"></i> -->
          <el-button
            type="success"
            @click="handleAdd"
            size="small"
            style="float: left; margin-bottom: 5px"
            >添加</el-button
          >

          <el-table
            :data="dataSource"
            style="width: 100%"
            max-height="300"
            border
          >
            <el-table-column prop="name" label="ip" :resizable="false">
              <template slot-scope="scope">
                <el-input v-model="scope.row.name" placeholder=""></el-input>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="" label="域名" :resizable="false">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.name"
                  placeholder=""
                  @input="change($event)"
                ></el-input>
              </template>
            </el-table-column> -->
            <!-- <el-table-column prop="pass" label="密码" :resizable="false">
              <template slot-scope="scope">
                <el-input v-model="scope.row.pass" placeholder=""></el-input>
              </template>
            </el-table-column> -->
        

            <el-table-column
              label="操作"
              width="120"
              fixed="right"
              :resizable="false"
            >
              <template slot-scope="scope">
             
                <a
                  href="javascript:;"
                  @click="() => onDelete(scope.$index, dataSource)"
                  >删除</a
                >
                <!-- </a-popconfirm> -->
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dataSet(title)">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="详情" :visible.sync="dialogDes" width="30%">
      <!-- <el-descriptions
                class="margin-top"
                title=""
                :column="4"
                v-for="(item,index) in disk_status"
                :key='index'
              
              >
                <el-descriptions-item>
                  <template slot="label">
                    目录
                  </template>
                  {{item.path}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    总空间
                  </template>
                  {{item.allspace}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    已用空间
                  </template>
                  {{item.usedspace}}
                </el-descriptions-item> <el-descriptions-item>
                  <template slot="label">
                    使用百分比
                  </template>
                  {{item.diskpercent}}
                </el-descriptions-item>
  
               
              </el-descriptions> -->
      <el-divider content-position="left">磁盘</el-divider>
      <el-table :data="disk_status" border style="width: 100%" height="250">
        <el-table-column prop="path" label="目录" width="180">
        </el-table-column>
        <el-table-column prop="allspace" label="总空间" width="180">
        </el-table-column>
        <el-table-column prop="usedspace" label="已用空间"> </el-table-column>
        <el-table-column prop="diskpercent" label="使用百分比">
        </el-table-column>
      </el-table>
      <el-divider content-position="left">网络</el-divider>
      <el-table :data="inoutspeed" border style="width: 100%" height="250">
        <el-table-column
          type="index"
          :index="indexFunW"
          width="50"
          label="网口"
          prop="path"
        >
          <!-- <el-table-column prop="path" label="网口" width="180"> -->
        </el-table-column>
        <el-table-column prop="inspeed" label="输入流量" width="180">
        </el-table-column>
        <el-table-column prop="outspeed" label="输出流量"> </el-table-column>
        <!-- <el-table-column prop="diskpercent" label="使用百分比"> </el-table-column> -->
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogDes = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!---标签设置弹框-->
    <el-dialog
      title="设置标签"
      append-to-body
      :visible.sync="dialogVisibleTag"
      width="30%"
    >
      <div>
        <el-tabs :tab-position="tabPosition" style="height: 400px">

          <el-tab-pane
            :label="item.title"
            v-for="(item, index) in dynamicTags"
            :key="index"
          >
       
            <el-tag
              @click="tabFun(itemii._id, itemii.title, itemii.color, indexii)"
              v-for="(itemii, indexii) in item.labels"
              :key="indexii"
              :style="`color:${itemii.color};cursor: pointer;margin:5px;`"
              >{{ itemii.title }}</el-tag
            >
          </el-tab-pane>
          <!-- <el-tab-pane label="配置管理">配置管理</el-tab-pane>
            <el-tab-pane label="角色管理">角色管理</el-tab-pane>
            <el-tab-pane label="定时任务补偿">定时任务补偿</el-tab-pane> -->
        </el-tabs>
        <div style="margin-top: 10px">
          <el-popover
            placement="top-start"
            title=""
            width="600"
            trigger="click"
          >
            <div>
              <div>
               <div style="    text-align: left;margin-bottom:15px;">
                <span style='display:inline-block;width:20%;'>择标签类型名称：</span>
                <el-select v-model="typeVaD" placeholder="请选择标签类型名称" size="mini" style="width:50%">
                  <el-option label="设备标签" value="设备标签"> </el-option>
                  <el-option label="服务标签" value="服务标签"> </el-option>
                  <el-option label="所在系统" value="所在系统"> </el-option>
                </el-select>
               </div> 
               <div style="    text-align: left;margin-bottom:15px; "><span style='display:inline-block;width:20%;'>标签名称：</span> <el-input style="width:50%" v-model="conVaD" placeholder="请输入标签名称" size="mini"></el-input></div>
               
                <el-button type="success" size="mini" style="float:right" @click="addBqFun">添加</el-button>
              </div>
            
            </div>
            <el-button
              slot="reference"
              type="success"
              icon="el-icon-circle-plus-outline"
              size="mini"
              >自定义标签</el-button
            >
          </el-popover>
        </div>
      </div>
      <div
        v-if="tagsCurrent.length > 0"
        style="border-top: 1px solid #000; margin-top: 10px; padding-top: 10px"
      >
        当前选中：<el-tag
          v-for="(tag, index) in tagsCurrent"
          :key="index"
          closable
          :style="`color:${tag.color}`"
          @close="closeTagFun(tag.title, index)"
        >
          {{ tag.title }}
        </el-tag>
      </div>
      <div
        v-else
        style="border-top: 1px solid #000; margin-top: 10px; padding-top: 10px"
      >
        当前选中标签：<span style="color: red">暂无</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleTag = false">关闭</el-button>
        <!-- <el-button type="primary" @click="dialogVisibleTag = false"
            >确 定</el-button
          > -->
      </span>
    </el-dialog>
  </div>
</template>
  <script>
import inputIp from "../components/inputIp.vue";
export default {
  components: {
    inputIp,
  },
  data() {
    return {
       typeVaD: undefined,
      conVaD:'',
      _sys_creator: "",
      maxHeight: document.body.offsetHeight - 190,
      tabPosition: "left",
      dialogVisibleTag: false,
      tagsCurrent: [],
      czText: "显示操作栏",
      czL: false,
      disk_ok: [],
      dialogDes: false,
      currentTitle: "",
      currentHostname: "",
      lookValue: false,
      loading: false,
      limitVal: 15,
      companyV: "",
      locationV: "",
      typeV: "",
      dx: "",
      // qb:"全屏视图",
      isLook: true,
      idC: "",
      yz: false,
      hostdevD: true,
      currentPage4: 1,
      valueS: "title",
      options: [
        { name: "设备编号", id: "title" },
        // { name: "IP地址", id: "ip" },
        // { name: "主机名", id: "hostname" },
        // { name: "服务学校", id: "school" },
      ],
      company: [],
      locationAry: [],
      dialogFormVisible: false,
      input: "",
      id: "",
      dataSource: [],
      dataSourceHost: [],
      tableData: [],
      widthS: document.body.clientWidth,
      // addStyle:'',
      ruleFormA: {
        start_time: "",
        end_time: "",
        // state: "可用",
        title: "",
        ip: "",
        host: "",
        hostname: "",
        password: "",
        use: "",
        type: "",
        portnum:'',
        portrate:'',
        thruput:'',
        school: "",
        manager: "",
        // managerName: [],
        account: "",
        comment: "",
        hostdev: "",
        location: "",
        company: "",
        seninfo: "",
      
      },
      total: null,
      showpsw: null,
      inoutspeed: [],
      rulesA: {
        title: [{ required: true, message: "请输入设备名称", trigger: "blur" }],
        ip: [
          { required: true, message: "请输入ip地址", trigger: "blur" },
          {
            validator: this.checkIp,
            trigger: "blur",
          },
        ],
        hostname: [
          { required: true, message: "请输入主机名", trigger: "blur" },
          {
            validator: this.checkHostname,
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入管理员密码", trigger: "blur" },
          {
            min: 6,
            max: 30,
            message: "密码最少输入6位",
            trigger: "blur",
          },
        ],
        // use: [{ required: true, message: "请输入用途", trigger: "blur" }],
      },
      title: "编辑",
      fzAry: [],
      addType: "",
      hostdevAry: [],
      companyAry: [],
      dynamicTags: [],
      inputVisible: false,
      inputValue: "",
      disk_status: [],
      wlJAry: [],
    };
  },
  props: {
    qb: String,
    companyAryD: [],
  },

  watch: {
    companyAryD: {
      handler(val) {
        console.log(val);
        // 所属单位
        this.companyFun();
        this.companyV = "";
        this.init(1, this.limitVal);
      },
    },
  },

  mounted() {
    this.companyFun();
    setInterval(() => {
      this.init(this.currentPage4, this.limitVal);
    }, 60000);
    this.init(1, this.limitVal);
    console.log(this.widthS);
    // this.hostdevFun();
    this.locationFun();

    this.fzInit();
    this.labelFun();
  },
  methods: {
      //添加标签
      addBqFun(){
      if(!this.typeVaD){
        this.$message.info('请选择标签类型名称！');
        return;

      }
      if(!this.conVaD){
        this.$message.info('请输入标签名称!');
        return;

      }
  
      let data = {
        op: "addlabel",
        type: this.typeVaD,
        title:this.conVaD
        // name: name,
      };
      let _this = this;
      this.$ajax
        .post(this.label, _this.Qs.stringify(data))

        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.$message.success(response.data.msg);
            _this.labelFun();
            // _this.init(_this.currentPage4, _this.limitVal);
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    goLink(){
      window.open('https://cacti.educg.net/mrtg/')
    },
    // 同步主机名
    zjName(id, name) {
      let data = {
        op: "sethostname",
        _id: id,
        // name: name,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.$message("主机名同步中，5分钟左右可尝试刷新查看！");
            // _this.init(_this.currentPage4, _this.limitVal);
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    czFun() {
      if (this.czText == "显示操作栏") {
        this.czText = "关闭操作栏";
        this.czL = true;
        //在执行完跳转页码的数据请求后
        this.$nextTick(() => {
          this.$refs.table.bodyWrapper.scrollTop = 0;
        });
      } else {
        this.czText = "显示操作栏";
        this.czL = false;
      }
    },
    // 物理机关联的虚拟机
    showW(id) {
      let data = {
        op: "vmdevbyhost",
        _id: id,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.wlJAry = response.data.data;
            // _this.total = response.data.data.recordcount;
            // _this.tableData = response.data.data.data;
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 删除标签
    closeTagFun(title, index) {
      this.tagsCurrent.splice(index, 1);
      // debugger
      // delete  this.tagsCurrent[index]
      console.log(this.tagsCurrent);
    },
    // 选中标签
    tabFun(id, title, color, index) {
      // let curTag = this.tagsCurrent.indexOf(title);
      if (this.tagsCurrent.length > 0) {
        debugger;
        let temp = true;
        for (let i = 0; i < this.tagsCurrent.length; i++) {
          if (this.tagsCurrent[i].title == title) {
            temp = false;
            break;
          } else {
            temp = true;
          }
        }
        if (temp) {
          this.tagsCurrent.push({ title: title, id: id, color: color });
        } else {
          this.$message("已选中");
        }
      } else {
        this.tagsCurrent.push({ title: title, id: id, color: color });
      }
      console.log(this.tagsCurrent);
    },
    // 设置标签
    setTagFun() {
      this.dialogVisibleTag = true;
    },
    // 获取标签
    labelFun() {
      let data = {
        op: "labellist",
        labeltype: "",
      };
      let _this = this;
      this.$ajax
        .post(this.label, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.dynamicTags = response.data.data;
            console.log('AAAAAAAAAAAAAAAA2',_this.dynamicTags)
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    des(value) {
      this.dialogDes = true;
      console.log(value.disk_status);
      console.log(value.inoutspeed);
      this.disk_status = value.disk_status;
      console.log(this.disk_status);
      this.inoutspeed = value.inoutspeed;
    },
    // handleClose(tag) {
    //   this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    // },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    // handleInputConfirm() {
    //   let inputValue = this.inputValue;
    //   if (inputValue) {
    //     this.dynamicTags.push(inputValue);
    //   }
    //   this.inputVisible = false;
    //   this.inputValue = "";
    // },
    indexFun(index) {
      return this.limitVal * (this.currentPage4 - 1) + index + 1;
    },
    rowdbFun(row) {
      this.add("editdev", row, "编辑");
    },
    cellFun(row) {
      console.log(row);
    },
    indexFunW(index) {
      return index + 1;
    },
    refreshinit(page, limit) {
      let data = {
        op: "search",
        value: this.input,
        type: this.valueS,
        page: page,
        limit: limit,
        location: this.locationV,
        company: this.companyV,
        // devtype: this.typeV,
      };
      let _this = this;
      this.$ajax
        .post(this.switchdev, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.total = response.data.data.recordcount;
            _this.tableData = response.data.data.data;
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    refresh(id) {
      let data = {
        op: "refreshsnmp",
        _id: id,
        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            // console.log(response.data.data);
            _this.$message({
              message: response.data.msg,
              type: "success",
            });
            _this.refreshinit(_this.currentPage4, _this.limitVal);
          } else if (response.data.value == "sessionerror") {
            
            _this.$message.error(response.data.msg);
            // setTimeout(() => {
            //   _this.$router.push({ path: "/" });
            // }, 2500);
          } else {
            _this.$message({
              message: response.data.msg,
              type: "warning",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    change() {
      console.log(7);
      this.$forceUpdate();
    },
  
    // ip校验格式
    checkIp(rule, value, callback) {
      let structRight = RegExp(
        /^([1-9]?\d|1\d{2}|2[0-4]\d|25[0-5])(\.([1-9]?\d|1\d{2}|2[0-4]\d|25[0-5])){3}$/
      ).test(value);
      if (!structRight) {
        callback(new Error("IP地址格式有误!"));
      } else {
        callback();
      }
    },
    // 主机名校验格式
    checkHostname(rule, value, callback) {
      console.log(3);
      let structZ = RegExp(/[\u4E00-\u9FA5]/).test(value);
      if (structZ) {
        callback(new Error("主机不能输入中文!"));
      } else {
        callback();
      }
    },
    anmpFun(id) {
      this.loading = true;
      let data = {
        op: "installsnmp",
        _id: id,
        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          _this.loading = false;
          if (response.data.value == "ok") {
            // console.log(response.data.data);
            _this.$message({
              message: response.data.msg,
              type: "success",
            });
            _this.init(_this.currentPage4, _this.limitVal);
          } else {
            _this.$message({
              message: response.data.msg,
              type: "warning",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 15.查看敏感信息验证码获取接口

    look(seninfo) {
      console.log(seninfo);
      if (seninfo === false) {
        this.lookValue = true;
      } else {
        this.ruleFormA.seninfo = seninfo;
        this.isLook = false;
        this.yz = true;
      }
    },
    lookFun() {
      // 校验通过
      let data = {
        op: "getvfycode",
        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          _this.lookValue = false;
          if (response.data.value == "ok") {
            // console.log(response.data.data);
            _this.$message({
              message: response.data.msg,
              type: "success",
            });
            _this.isLook = false;
          } else if (response.data.value == "fail") {
            _this.isLook = false;
          } else {
            _this.$message({
              message: response.data.msg,
              type: "warning",
            });
            _this.isLook = true;
            // console.log( _this.isLook)
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 提交验证码
    yanz() {
      // console.log(id)
      // 调取接口
      // 校验通过
      if (this.dx.trim() == "") {
        this.$message({
          message: "请输入验证码！",
          type: "warning",
        });
        return;
      }
      let data = {
        op: "getseninfo",
        _id: this.id,
        code: this.dx,

        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            // console.log(response.data.data);
            _this.ruleFormA.seninfo = response.data.data;
            _this.isLook = false;
            _this.yz = true;
          } else {
            _this.$message({
              message: response.data.msg,
              type: "warning",
            });
            // _this.isLook = true;
            _this.yz = false;
            console.log(_this.isLook);
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      console.log(this.yz);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.limitVal = val;
      this.init(1, val);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    companyFunI() {
      this.init(1, this.limitVal);
    },
    locationFunI() {
      this.init(1, this.limitVal);
    },
    typeI() {
      this.init(1, this.limitVal);
    },
    resFun(){
      this.input = '';
      this.valueS = 'title';
      this.currentPage4 = 1;
      this.limitVal = 15;
      this.companyV = '';
      this.locationV = ''

      this.init(this.currentPage4,this.limitVal )
    },

    init(page, limit = this.limitVal) {
      this.currentPage4 = page;
      let data = {
        op: "search",
        value: this.input,
        type: this.valueS,
        page: page,
        limit: limit,
        location: this.locationV,
        company: this.companyV,
        // devtype: this.typeV,
      };
      let _this = this;
      this.$ajax
        .post(this.switchdev, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.total = response.data.data.recordcount;
            _this.tableData = response.data.data.data;
            //在执行完跳转页码的数据请求后

            _this.$refs.table.bodyWrapper.scrollTop = 0;

            if (_this.currentPage4 != 1 && _this.tableData.length == 0) {
              // 解决删除最后一页的只有一条数据的情况，那就从上一页开始查询
              _this.currentPage4--;
              _this.init(_this.currentPage4, _this.limitVal);
            }
            // _this.fzInit(response.data.data.data);
          } else if (response.data.value == "sessionerror") {
            _this.$message.error(response.data.msg);
            setTimeout(() => {
              _this.$router.push({ path: "/" });
            }, 2500);
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    goNewFun(ssh) {
      let routeUrl = this.$router.resolve({
        path: ssh,
      });
      console.log(routeUrl.href);
      console.log(routeUrl);
      window.open(routeUrl.href.split("#/")[1], "_blank");
    },
    changeIp(ip) {
      console.log(ip);
      this.ruleFormA.ip = ip;
    },
    getIpVal(ip) {
      console.log(ip);
      this.ruleFormA.ip = ip;
    },
    fzInit() {
      let data = {
        op: "userlist",
        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.switchdev, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.fzAry = response.data.data;

            console.log(_this.tableData);
          } else {
            // _this.tableData = Ary;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleClick(row) {
      console.log(row);
    },
    handleAdd() {
      const { count, dataSource } = this;
      const newData = {
        name: "",
     
        //  email: "",
      };
      this.dataSource = [...dataSource, newData];
      this.count = count + 1;
      //  this.dis = false;
    },
    handleAddHost() {
      const { countHost, dataSourceHost } = this;
      const newData = { name: "" };
      this.dataSourceHost = [...dataSourceHost, newData];
      console.log(this.dataSourceHost);
      this.countHost = countHost + 1;
    },
    onDelete(index, rows) {
      rows.splice(index, 1);
      // this.dis = false;
    },
    changeFun(id) {
      console.log(id);
      this.valueS = id;
    },
    add(type, data, title) {
      if (this.$refs.ruleFormA !== undefined)
        this.$refs.ruleFormA.resetFields();

      this.dx = "";
      this.dialogFormVisible = true;
      this.title = title;
      this.addType = type;
      this.isLook = true;
      this.yz = false;
      console.log(data);
      if (data != "") {
        // 编辑
        this.ruleFormA.title = data.title;
        this.currentTitle = data.title;
        this.ruleFormA.ip = data.ip;
        this.ruleFormA.hostname = data.hostname;
        this.currentHostname = data.hostname;
        this.ruleFormA.host = data.host;
        this.ruleFormA.password = data.password;
        this.ruleFormA.use = data.use;
        this.ruleFormA.type = data.type;
        this.ruleFormA.school = data.school;
        this.ruleFormA.manager = data.manager;
        // this.ruleFormA.state = data.state;
        console.log(data.manager);
        this.ruleFormA.account = data.account;
        this.ruleFormA.comment = data.comment;
        if (data.company) {
          this.ruleFormA.company = data.company;
        } else {
          this.ruleFormA.company = "";
        }
        if (data.location) {
          this.ruleFormA.location = data.location;
        } else {
          this.ruleFormA.location = "";
        }
        if (data.seninfo === false) {
          this.ruleFormA.seninfo = '';
        } else {
          this.ruleFormA.seninfo = data.seninfo;
        }
        console.log(this.ruleFormA.seninfo);

        this.id = data._id;
        // 宿主机
        if (data.hostdev && data.hostdev != "") {
          this.hostdevD = false;
          this.ruleFormA.hostdev = data.hostdev;
        } else {
          this.hostdevD = true;
          this.ruleFormA.hostdev = "";
        }
        if (data.iplist && data.iplist != "") {
          this.dataSource = data.iplist;
        } else {
          this.dataSource = [];
        }
        if (data.host && data.host != "") {
          this.dataSourceHost = data.host;
        } else {
          this.dataSourceHost = [];
        }
        if (data._sys_label) {
          this.tagsCurrent = data._sys_label;
        } else {
          this.tagsCurrent = [];
        }

        this._sys_creator = data._sys_creator;
        console.log(this._sys_creator);
        this.showpsw = data.showpsw;
        this.ruleFormA.start_time = data.start_time;
        this.ruleFormA.end_time = data.end_time;
        this.ruleFormA.portnum = data.portnum;
        this.ruleFormA.portrate = data.portrate;
        this.ruleFormA.thruput = data.thruput;
      } else {
        this._sys_creator = "";
        // this.ruleFormA.state = "可用";
        this.ruleFormA.start_time = "";
        this.ruleFormA.end_time = "";
        this.ruleFormA.portnum = '';
        this.ruleFormA.portrate = '';
        this.ruleFormA.thruput = '';
        this.tagsCurrent = [];
        this.dataSource = [];
        this.dataSourceHost = [];
        this.id = "";
        this.ruleFormA.title = "";
        this.ruleFormA.ip = "";
        this.ruleFormA.hostname = "";
        this.ruleFormA.password = "";
        this.ruleFormA.use = "";
        this.ruleFormA.type = undefined;
        this.ruleFormA.school = "";
        this.ruleFormA.manager = "";
        this.ruleFormA.account = [];
        this.ruleFormA.comment = "";
        this.ruleFormA.hostdev = "";
        this.showpsw = true;
        this.ruleFormA.company = undefined;
        this.ruleFormA.location = undefined;
        this.ruleFormA.seninfo = "";
        this.hostdevD = true;
      }
    },
    typeFun(val) {
      console.log(val);
      if (val == "虚拟机") {
        this.hostdevD = false;
      } else {
        this.hostdevD = true;
        this.ruleFormA.hostdev = "";
      }
    },
    del(id, title) {
      let data = {
        op: "deldev",
        _id: id,
      };
      let _this = this;
      this.$ajax
        .post(this.switchdev, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.$message({
              message: title + response.data.msg,
              type: "success",
            });

            _this.init(_this.currentPage4, _this.limitVal);
          } else if (response.data.value == "sessionerror") {
            _this.$message.error(response.data.msg);
            setTimeout(() => {
              _this.$router.push({ path: "/" });
            }, 2500);
          } else {
            _this.$message.info({
              message: response.data.msg,
              type: "success",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 宿主机列表获取接口
    hostdevFun() {
      let data = {
        op: "hostdevlist",
        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            console.log(response.data.data);
            _this.hostdevAry = response.data.data;
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    companyFun() {
      let data = {
        op: "companylist",
        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.switchdev, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            console.log(response.data.data);
            _this.companyAry = response.data.data;
            console.log(_this.companyAry);
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    locationFun() {
      let data = {
        op: "locationlist",
        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.switchdev, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            console.log(response.data.data);
            _this.locationAry = response.data.data;
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    quan() {
      if (this.qb == "全屏视图") {
        // this.qb = '还原视图'
        this.$emit("quan", false);
      } else {
        // this.qb = '全屏视图'
        this.$emit("quan", true);
      }
    },
    timeTrans(time, type) {
      let date = new Date(new Date(time).getTime() + 8 * 3600 * 1000);
      date = date.toJSON();
      if (type === 1) {
        date = date.substring(0, 10);
      } else {
        date = date.substring(0, 19).replace("T", " ");
      }
      return date;
    },

    dataSet() {
      this.$refs.ruleFormA.validate((valid) => {
        if (valid) {
          // if (this.ruleFormA.type == "虚拟机") {
          //   if (this.ruleFormA.hostdev == "") {
          //     this.$message.error("类型为“虚拟机”时，宿主机为必选");
          //     return;
          //   }
          // }
          console.log(this.ruleFormA.seninfo);
          let currentName = [];

          if (
            this.addType == "editdev" &&
            this.currentTitle == this.ruleFormA.title
          ) {
            // 名称没变就不提交字段
            currentName = [];
          }
   
          else {
            currentName = this.ruleFormA.title;
          }
          // let currentHostname = [];
          // if (
          //   this.addType == "editdev" &&
          //   this.currentHostname.trim() == this.ruleFormA.hostname.trim()
          // ) {
          //   // 名称没变就不提交字段
          //   currentHostname = [];
          // }
       
          // else {
          //   currentHostname = this.ruleFormA.hostname.trim();
          // }
          let _sys_label_cur = "";
          // if(this.tagsCurrent.length>0){
          //   for(let i=0;i<this.tagsCurrent.length;i++){
          //     if(_sys_label_cur!=''){
          //       _sys_label_cur +=','+this.tagsCurrent[i].title
          //     }
          //     else {
          //       _sys_label_cur +=this.tagsCurrent[i].title
          //     }

          //   }
          // }

          let data = {
            op: this.addType,
            _id: this.id,
            title: currentName,
            portnum: this.ruleFormA.portnum,
            ip:this.ruleFormA.ip,
            // hostname: currentHostname,
             portrate: this.ruleFormA.portrate,
             thruput: this.ruleFormA.thruput,
            type: this.ruleFormA.type,
            // school: this.ruleFormA.school,
            manager: this.ruleFormA.manager,
            // account: this.dataSource.length == 0 ? "" : this.dataSource,
            comment: this.ruleFormA.comment,
            iplist:this.dataSource.length == 0 ? "" : this.dataSource,
            // hostdev: this.ruleFormA.hostdev,
            company: this.ruleFormA.company,
            location: this.ruleFormA.location,
            // seninfo: this.ruleFormA.seninfo,
            // host: this.dataSourceHost.length == 0 ? "" : this.dataSourceHost,
            _sys_label: this.tagsCurrent.length == 0 ? "" : this.tagsCurrent,
            // state: this.ruleFormA.state,
            start_time: this.ruleFormA.start_time?this.timeTrans(this.ruleFormA.start_time, 1):'',
            end_time: this.ruleFormA.end_time?this.timeTrans(this.ruleFormA.end_time, 1):'',
            // _sys_creator: this._sys_creator,
          };
          let _this = this;
          this.$ajax
            .post(this.switchdev, _this.Qs.stringify(data))
            .then(function (response) {
              console.log(response);
              if (response.data.value == "ok") {
                _this.$message({
                  message: response.data.msg,
                  type: "success",
                });
                _this.dialogFormVisible = false;
                if (_this.addType == "adddev") {
                  _this.currentPage4 = 1;
                  _this.init(1, _this.limitVal);
                } else {
                  _this.init(_this.currentPage4, _this.limitVal);
                }

                // _this.hostdevFun();
              } else if (response.data.value == "sessionerror") {
                _this.$message.error(response.data.msg);
                setTimeout(() => {
                  _this.$router.push({ path: "/" });
                }, 2500);
              } else {
                _this.$message.error(response.data.msg);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
        }
      });
    },
    currentP(size) {
      console.log(size);
      this.currentPage4 = size;
      this.init(size, this.limitVal);
    },
    preFun(size) {
      this.init(size, this.limitVal);
    },
    nextFun(size) {
      this.init(size, this.limitVal);
    },
  },
};
</script>
  <style lang="scss" scoped>
p .fk {
  margin-bottom: 5px;
  color: #fff;
}
.el-input__inner {
  border: none;
}
.about {
  padding: 10px;
}
.qiehuan {
  min-height: 40px;
  overflow: hidden;
  .qh {
    float: right;
    margin-left: 10px;
  }
  .add {
    float: right;
    margin-bottom: 10px;
  }
  .sou {
    float: left;
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;
  }
}
</style>
  <style>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px !important;
  vertical-align: bottom;
}
.about .el-form-item__label {
  /* color: #d4d4d4 !important; */
}
.el-dialog {
  margin-top: 3vh !important;
  width: 60% !important;
}
.el-select {
  width: 100%;
}
.el-form-item {
  width: 50%;
  display: inline-block;
}

.el-textarea__inner {
  resize: none !important;
}
.tabs .el-tag {
  float: left;
}
.min .el-textarea__inner {
  min-height: 150px !important;
}
.el-descriptions {
  border-bottom: 1px solid #ccc;
  padding: 17px 0;
}
/* .el-tag{
    float:left;
  } */
thead tr th {
  height: 40px;

  /* background-color: #e3e3e3 !important; */
  /* color: #5b5b5b; */
  font-weight: bold;
}
.el-pagination {
  float: right;
  margin-top: 10px;
}
.el-table .el-table__cell {
  padding: 2px 0 !important;
}
.el-table .el-table__cell {
  margin: 0 !important;
}
@media (max-width: 800px) {
  .el-dialog {
    width: 98% !important;
  }
  .el-form-item {
    width: 100%;
  }
}
</style>
  