import { render, staticRenderFns } from "./wangEditor.vue?vue&type=template&id=079e817e&"
import script from "./wangEditor.vue?vue&type=script&lang=js&"
export * from "./wangEditor.vue?vue&type=script&lang=js&"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./wangEditor.vue?vue&type=style&index=1&id=079e817e&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports